import {
    DISPLAY_REPORT_PAGE_ERROR,
    GET_PATIENT_COMPLIANCE,
    GET_REFILL_PATIENT_OUTLET
} from '../../constants/action-types';

export const displayReportError = ({ status, message }) => ({
    type: DISPLAY_REPORT_PAGE_ERROR,
    payload: {
        status,
        message
    }
});

export const displayPatientComplianceReport = payload => ({
    type: GET_PATIENT_COMPLIANCE,
    payload
});

export const displayRefillPatientOutletReport = payload => ({
    type: GET_REFILL_PATIENT_OUTLET,
    payload
});