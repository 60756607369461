import React from 'react';
import { connect } from 'react-redux';

// Components
import { Grid } from '@material-ui/core';
import { DataCard } from '../ui/Card';
import { Typography } from '@material-ui/core';

// Actions
import { getPatientComplianceReport } from '../../actions/report';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

class PatientCompliancePage extends React.Component {
  componentDidMount(){
    this.getPatientCompliance();
  }

  getPatientCompliance = () => {
    const { dispatch } = this.props;

    dispatch(getPatientComplianceReport());
  }

  displayPatientCompliance = (patientComplianceReport) => {    
      let brandNames = [],
          patientCompliant = [],
          patientNonCompliant = [];
      
        patientComplianceReport.forEach(product => {
          brandNames.push(`${product.brand_name}-${product.type}`);
          patientCompliant.push(parseInt(product.compliant));
          patientNonCompliant.push(parseInt(product.non_compliant));
        });

    const chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: brandNames
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: 'Total Patient Compliance'
        },
        labels: {
          formatter: function () {
            return this.value + '%';
          }
        }
      },
      legend: {
        align: 'left',
        x: 30,
        verticalAlign: 'top',
        y: 5,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'percent'
        }
      },
      series: [{
        name: 'Complaint',
        data: patientCompliant
      }, {
        name: 'Non Complaint',
        data: patientNonCompliant
      }]
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }

  render() {
    const { ui, report: { patientComplianceReport } } = this.props;

    return (
      <Grid spacing={16} container>
        <Grid item xs={12}>
          <DataCard
            title="Patient Compliance"
            subtitle="Monthly Report for Compliance Status of patients per brand"
            isLoading={ui.patientCompliance.isLoading}
          >
            {patientComplianceReport.length > 0 ? (
              <>
                {this.displayPatientCompliance(patientComplianceReport)}
              </>
            ) : (
              <Typography>No data found</Typography>
            )}
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  report: state.report,
  ui: state.ui,
  filter: state.filter,
});

export default connect(mapStateToProps)(PatientCompliancePage);
