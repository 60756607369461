import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import Select from '../../forms/Select';

/**
 * Actions
 */
import {
  applyFilters,
  clearFilters,
  getFilters,
  getProducts,
} from '../../../actions/filter';

/**
 * Material UI - Core
 */
import {
  Grid,
  Tabs,
  Tab,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Typography,
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  withStyles,
  CircularProgress,
  InputAdornment
} from '@material-ui/core';

/**
 * Material UI - Icon
 */
import {
  DateRange as DateRangeIcon,
  Category as CategoryIcon,
  People as PeopleIcon,
  FilterList as FilterListIcon,
  Clear as ClearIcon,
} from '@material-ui/icons';

/**
 * Custom UI Components
 */
import DatePicker from '../../ui/DatePicker';

/**
 * Styles
 */
const styles = theme => {
  return {
    sectionLabel: {
      color: theme.palette.secondary.main,
      fontWeight: 800,
      fontSize: '1em',
      letterSpacing: 1,
      padding: '16px 24px',
      textTransform: 'uppercase',
    },
    tabRoot: {
      minWidth: 30,
    },
    formLabelRoot: {
      color: theme.palette.secondary.main,
      marginBottom: 8 * 2,
      textTransform: 'uppercase',
    },
    checkboxRoot: {
      height: 8 * 4,
    },
    radioRoot: {
      height: 8 * 4,
    },
  };
};

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

const dateFormat = 'YYYY-MM-DD';

class Filter extends Component {
  state = {
    dateFrom: moment()
      .startOf('year')
      .format(dateFormat),
    dateTo: moment().format(dateFormat),
    selectedBrandIds: [],
    selectedProductIds: [],
    selectedProducts: [],
    selectedAccounts: [],
    selectedOrganizations: [],
    selectedAccountType:[],
    selectedZuelligAccountName:[],
    selectedZuelligAccountGroupName: [],
    selectedSalesExecutive: [],
    entityType: null,
    activeTab: 0,
    organizationList:[],
    accountList: [],
    zuelligAccountNameList: [],
    zuelligAccountGroupNameList: [],
    salesExecutiveList: [],
    accountTypeList: []
  };

  async componentDidMount() {
   await this.props.dispatch(getFilters());
    
    const organizations = this.props.filter.retrievedOrganizations.map(({ organization_branch }) => ({
        label: `${organization_branch}`,
        value: organization_branch
     }));

    const accounts = this.props.filter.retrievedAccounts.map(({ account_name, account_number }) => ({
        label: `${account_name}`,
        value: account_number
    }));

    const zuelligAccountName = this.props.filter.retrievedZuelligAccountName.map(({ zuellig_account_name}) => ({
      label: `${zuellig_account_name}`,
      value: zuellig_account_name
    }));

    const zuelligAccountGroupName = this.props.filter.retrievedZuelligAccountGroupName.map(({ zuellig_group_name}) => ({
      label: `${zuellig_group_name}`,
      value: zuellig_group_name
    }));

    const salesExecutive = this.props.filter.retrievedSalesExecutive.map(({ sales_executive_name}) => ({
      label: `${sales_executive_name}`,
      value: sales_executive_name
    }));
    

     this.setState({
       organizationList: organizations,
       accountList: accounts,
       zuelligAccountNameList: zuelligAccountName,
       zuelligAccountGroupNameList: zuelligAccountGroupName,
       salesExecutiveList: salesExecutive,
       accountTypeList: [
         {
           label: `Individual`,
           value: `Individual`,
         },
         {
           label: `Group`,
           value: `Group`,
         },
       ],
     });
 
  }

  isBrandAlreadySelected = brandId =>
    this.state.selectedBrandIds.some(
      selectedBrandId => selectedBrandId === brandId
    );
  isProductAlreadySelected = productId =>
    this.state.selectedProductIds.some(
      selectedProductId => selectedProductId === productId
    );
  isAccountAlreadySelected = accountNumber =>
    this.state.selectedAccounts.some(
      selectedAccount => selectedAccount === accountNumber
    );
  isOrganizationAlreadySelected = organizationName =>
    this.state.selectedOrganizations.some(
      selectedOrganization => selectedOrganization === organizationName
    );

  onSelectTab = (event, value) => this.setState({ activeTab: value });
  onChangeDateFrom = momentDate =>
    this.setState({
      dateFrom: momentDate ? momentDate.format(dateFormat) : null,
    });
  onChangeDateTo = momentDate =>
    this.setState({
      dateTo: momentDate ? momentDate.format(dateFormat) : null,
    });

  onSelectChange = name => value => this.setState({ [name]: value });

  //TODO: refactor
  Timeframe = () => {
    const { classes, filter, ui } = this.props;
    const { activeTab, dateFrom, dateTo, entityType } = this.state;
    const maxDate = moment(dateTo, dateFormat)
      .subtract(1, 'day')
      .format(dateFormat);
    const minDate = moment(dateFrom, dateFormat)
      .add(1, 'day')
      .format(dateFormat);

    return <TabContainer>
      <FormControl>
        <FormLabel classes={{ root: classes.formLabelRoot }}>
          Timeframe
        </FormLabel>
        <DatePicker
          fullWidth={false}
          label="From"
          maxDate={maxDate}
          name="date"
          onChange={this.onChangeDateFrom}
          style={{ marginBottom: 16 }}
          value={dateFrom}
        />

        <DatePicker
          fullWidth={false}
          label="To"
          minDate={minDate}
          name="date"
          onChange={this.onChangeDateTo}
          value={dateTo}
        />
      </FormControl>
    </TabContainer>
  };

  //TODO: refactor
  entityType = () => {
    const { classes, filter} = this.props;
    const {
      retrievedAccounts,
      retrievedOrganizations,
      retrievedZuelligAccountName,
      retrievedZuelligAccountGroupName,
      retrievedSalesExecutive
    } = filter;

    const {
      entityType,
      organizationList: stateOrganizationList,
      selectedOrganizations,
      accountList: stateAccountList,
      selectedAccounts,
      selectedZuelligAccountName,
      zuelligAccountNameList: stateZuelligAccountNameList,
      selectedAccountType,
      accountTypeList: stateAccountTypeList,
      selectedZuelligAccountGroupName,
      zuelligAccountGroupNameList: stateZueulligAccountGroupNameList,
      selectedSalesExecutive,
      salesExecutiveList: stateSalesExecutiveList
    } = this.state;

    const accountList =
      retrievedAccounts.length > 0 &&
      <FormControl
      
      style={{width: 200}}>
        <Select 
          isClearable 
          isMulti
          options={stateAccountList}
          placeholder={"Account"}
          onChange={this.onSelectChange('selectedAccounts')}
          value={selectedAccounts} />
      </FormControl>;

    const organizationList =
      retrievedOrganizations.length > 0 &&
      <FormControl
      
      style={{width: 200}}>
        <Select 
        isClearable 
        isMulti
        options={stateOrganizationList}
        placeholder={"Organization"}
        onChange={this.onSelectChange('selectedOrganizations')}
        value={selectedOrganizations} />
        </FormControl>;

    const zuelligAccountNameList =
      retrievedZuelligAccountName.length > 0 &&
      <FormControl
      
      style={{width: 200}}>
        <Select 
        isClearable 
        isMulti
        options={stateZuelligAccountNameList}
        placeholder={"Zuellig Account Name"}
        onChange={this.onSelectChange('selectedZuelligAccountName')}
        value={selectedZuelligAccountName} />
        </FormControl>;

    const zuelligAccountTypeList =
      <FormControl
      
      style={{width: 200}}>
        <Select 
        isClearable 
        options={stateAccountTypeList}
        placeholder={"Account Type"}
        onChange={this.onSelectChange('selectedAccountType')}
        value={selectedAccountType} />
        </FormControl>;

    const zuelligAccountGroupNameList =
      retrievedZuelligAccountGroupName.length > 0 &&
      <FormControl
      
      style={{width: 200}}>
        <Select 
        isClearable 
        isMulti
        options={stateZueulligAccountGroupNameList}
        placeholder={"Account Group Name"}
        onChange={this.onSelectChange('selectedZuelligAccountGroupName')}
        value={selectedZuelligAccountGroupName} />
        </FormControl>;

    const salesExecutiveList =
        retrievedSalesExecutive.length > 0 &&
        <FormControl
        
        style={{width: 200}}>
          <Select 
          isClearable 
          isMulti
          options={stateSalesExecutiveList}
          placeholder={"Sales Executive"}
          onChange={this.onSelectChange('selectedSalesExecutive')}
          value={selectedSalesExecutive} />
          </FormControl>;
    
  let entityList;
    if (entityType === 'account')
        entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Accounts
          </FormLabel>
          <FormGroup>{accountList}</FormGroup>
        </FormControl>
    );
    else if (entityType === 'organization')
      entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Organizations
          </FormLabel>
          <FormGroup>{organizationList}</FormGroup>
        </FormControl>
    );
    else if(entityType === 'zuelligAccountName'){
      entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Zuellig Account Name
          </FormLabel>
          <FormGroup>{zuelligAccountNameList}</FormGroup>
        </FormControl>
    );
    }
    else if(entityType === 'accountType'){
      entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Account Type
          </FormLabel>
          <FormGroup>{zuelligAccountTypeList}</FormGroup>
        </FormControl>
    );
    }
    else if(entityType === 'accountGroupName'){
      entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Account Group Name
          </FormLabel>
          <FormGroup>{zuelligAccountGroupNameList}</FormGroup>
        </FormControl>
    );
    }
    else if(entityType === 'salesExecutive'){
      entityList = (
        <FormControl style={{ marginTop: 16 }}>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Sales Executive
          </FormLabel>
          <FormGroup>{salesExecutiveList}</FormGroup>
        </FormControl>
    );
    }

    return  (
      <TabContainer>
        <FormControl>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Entity
          </FormLabel>
          <RadioGroup
            name={'entityType'}
            value={entityType}
            onChange={this.onSelectEntityType}
          >
            <FormControlLabel
              label="Zuellig Account Name"
              value="zuelligAccountName"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            />
            <FormControlLabel
              label="Account Type"
              value="accountType"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            />
            <FormControlLabel
              label="Account Group Name"
              value="accountGroupName"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            />
            { /* Do not inclue this in filters as request by Edrick Jan 30, 2020*/}
            {/* <FormControlLabel
              label="Sales Executive"
              value="salesExecutive"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            /> */}
            <FormControlLabel
              label="Account"
              value="account"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            />
            <FormControlLabel
              label="Organization"
              value="organization"
              control={<Radio classes={{ root: classes.checkboxRoot }} />}
            />
          </RadioGroup>
        </FormControl>
        {entityList}
      </TabContainer>
    );
  }

  onSelectBrand = async e => {
    const { selectedBrandIds, selectedProducts } = this.state;
    const { dispatch } = this.props;
    const brandId = parseInt(e.target.value, 10);
    const positionInArray = selectedBrandIds.indexOf(brandId);

    const newSelectedBrandIds = [...selectedBrandIds];
    if (positionInArray === -1) {
      newSelectedBrandIds.push(brandId);
      this.setState({ selectedBrandIds: newSelectedBrandIds });
    } else {
      newSelectedBrandIds.splice(positionInArray, 1);

      // Reset the state of the product
      const remainingProducts = selectedProducts.filter(
        ({ brand_id }) => brand_id !== brandId
      );
      const remainingProductIds = remainingProducts.map(data => data.id);

      this.setState({
        selectedBrandIds: newSelectedBrandIds,
        selectedProducts: remainingProducts,
        selectedProductIds: remainingProductIds,
      });
    }

    dispatch(getProducts(newSelectedBrandIds));
  };

  checkLoader = () => {

    const {
      accountLeaderboard,
      accounts,
      accountsView,
      brandMix,
      brands,
      monthlyEnrolledPatients,
      monthlyQuantitySold,
      monthlyTransactingPatients,
      monthlyTransactions,
      monthlyTransactionRefillersAndNewPatients,
      retailerRebateAndBoxes,
      organizationLeaderboard,
      organizations,
      organizationsView,
      patientCompliance,
      patientSummary,
      pharmacySummary,
      physicianSummary,
      productSummary,
      products,
      programSummary,
      rebateSummary,
      transactionFeed,
      
    } = this.props.ui;

    return accountLeaderboard.isLoading ||
    accounts.isLoading ||
    accountsView.isLoading ||
    brandMix.isLoading ||
    brands.isLoading ||
    monthlyEnrolledPatients.isLoading ||
    monthlyQuantitySold.isLoading ||
    retailerRebateAndBoxes.isLoading || 
    monthlyTransactingPatients.isLoading ||
    monthlyTransactions.isLoading ||
    monthlyTransactionRefillersAndNewPatients.isLoading ||
    organizationLeaderboard.isLoading ||
    organizations.isLoading ||
    organizationsView.isLoading ||
    patientCompliance.isLoading ||
    patientSummary.isLoading ||
    pharmacySummary.isLoading ||
    physicianSummary.isLoading ||
    productSummary.isLoading ||
    products.isLoading ||
    programSummary.isLoading ||
    rebateSummary.isLoading ||
    transactionFeed.isLoading ? true : false;
  }

  onSelectProduct = e => {
    const { selectedProducts } = this.state;
    const { availableProducts } = this.props.filter;
    let tempSelectedProducts = [];

    const productId = parseInt(e.target.value, 10);
    const selectedProduct = availableProducts.filter(
      product => product.id === productId
    );

    // Check if product id is already selected
    let isSelected = selectedProducts.some(
      selectedProduct => selectedProduct.id === productId
    );

    if (!isSelected)
      tempSelectedProducts = selectedProducts.concat(selectedProduct);
    else
      tempSelectedProducts = selectedProducts.filter(
        temp => temp.id !== productId
      );

    this.setState({
      selectedProducts: tempSelectedProducts,
      selectedProductIds: tempSelectedProducts.map(data => data.id),
    });
  };

  onSelectEntityType = e => this.setState({ entityType: e.target.value });

  onSelectAccount = e => {
    const { selectedAccounts } = this.state;
    const accountNumber = e.target.value;
    const positionInArray = selectedAccounts.indexOf(accountNumber);

    if (positionInArray === -1) selectedAccounts.push(accountNumber);
    else selectedAccounts.splice(positionInArray, 1);

    this.setState({ selectedAccounts });
  };

  onSelectOrganization = e => {
    const { selectedOrganizations } = this.state;
    const organizationBranch = e.target.value;
    const positionInArray = selectedOrganizations.indexOf(organizationBranch);

    if (positionInArray === -1) selectedOrganizations.push(organizationBranch);
    else selectedOrganizations.splice(positionInArray, 1);

    this.setState({ selectedOrganizations });
  };

  

  // For Applying Filters
  onSubmit = e => {
    e.preventDefault();
    const { dispatch, history } = this.props;
    const {
      dateFrom,
      dateTo,
      selectedBrandIds,
      selectedProductIds,
      selectedAccounts,
      selectedOrganizations,
      selectedZuelligAccountName,
      selectedAccountType,
      selectedZuelligAccountGroupName,
      selectedSalesExecutive,
      entityType,
    } = this.state;
    const location = history.location;

    const mappedOrganization =
      (selectedOrganizations &&
        selectedOrganizations.map(organization => organization.value)) ||
      [];
    const mappedAccount =
      (selectedAccounts &&
        selectedAccounts.map(account => account.value)) ||
      [];
    const mappedZuelligAccountName =
      (selectedZuelligAccountName &&
        selectedZuelligAccountName.map(
          zuelligAccountName => zuelligAccountName.value
        )) ||
      [];
    const mappedAccountType =
      (selectedAccountType && selectedAccountType.value) || '';
    const mappedZuelligAccountGroupName =
      (selectedZuelligAccountGroupName &&
        selectedZuelligAccountGroupName.map(
          zuelligAccountGroupName => zuelligAccountGroupName.value
        )) ||
      [];
    const mappedSalesExecutive =
      (selectedSalesExecutive &&
        selectedSalesExecutive.map(
          salesExecutive => salesExecutive.value
        )) ||
      [];
    

    dispatch(
      applyFilters(
        {
          dateFrom,
          dateTo,
          selectedBrandIds,
          selectedProductIds,
          selectedAccounts: entityType === 'account' ? mappedAccount : [],
          selectedOrganizations:
            entityType === 'organization' ? mappedOrganization : [],
          selectedZuelligAccountName:
            entityType === 'zuelligAccountName'
              ? mappedZuelligAccountName
              : [],
          selectedAccountType:
            entityType === 'accountType' ? mappedAccountType : '',
          selectedZuelligGroupName:
            entityType === 'accountGroupName'
              ? mappedZuelligAccountGroupName
              : [],
          selectedSalesExecutive:
            entityType === 'salesExecutive' ? mappedSalesExecutive : [],
        },
        location
      )
    );
  };

  // Clear/reset filters
  onClear = e => {
    e.preventDefault();
    const { dispatch, history } = this.props;
    const location = history.location;
    dispatch(clearFilters(location));
    this.setState({
      dateFrom: moment()
        .startOf('year')
        .format(dateFormat),
      dateTo: moment().format(dateFormat),
      selectedBrandIds: [],
      selectedProductIds: [],
      selectedAccounts: [],
      selectedOrganizations: [],
      selectedAccountType:[],
      selectedZuelligAccountName:[],
      selectedZuelligAccountGroupName: [],
      selectedSalesExecutive: [],
    });
  };

  render() {
    const { classes, filter, ui } = this.props;
    const {
      availableBrands,
      availableProducts,
      retrievedAccounts,
      retrievedOrganizations,
    } = filter;

    const {organizationList: stateOrganizationList, selectedOrganizations, accountList: stateAccountList, selectedAccounts } = this.state


    const { activeTab, dateFrom, dateTo, entityType } = this.state;
    const maxDate = moment(dateTo, dateFormat)
      .subtract(1, 'day')
      .format(dateFormat);
    const minDate = moment(dateFrom, dateFormat)
      .add(1, 'day')
      .format(dateFormat);

    const brandList =
      availableBrands.length > 0 &&
      availableBrands.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          control={
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              checked={this.isBrandAlreadySelected(id)}
              name={name}
              id={id.toString()}
              value={id.toString()}
              onChange={this.onSelectBrand}
            />
          }
          label={name}
        />
      ));

    const productList =
      availableProducts.length > 0 &&
      availableProducts.map(({ id, name }) => (
        <FormControlLabel
          key={id}
          control={
            <Checkbox
              classes={{ root: classes.checkboxRoot }}
              checked={this.isProductAlreadySelected(id)}
              name={name}
              id={id.toString()}
              value={id.toString()}
              onChange={this.onSelectProduct}
            />
          }
          label={name}
        />
      ));

      

    let entityList = '';

    // TODO: Change this to show/hide since it causes delay in re-rendering.
    // if (entityType === 'account')
    //   entityList = (
    //     <FormControl style={{ marginTop: 16 }}>
    //       <FormLabel classes={{ root: classes.formLabelRoot }}>
    //         Accounts
    //       </FormLabel>
    //       <FormGroup>{accountList}</FormGroup>
    //     </FormControl>
    //   );
    // else if (entityType === 'organization')
    //   entityList = (
    //     <FormControl style={{ marginTop: 16 }}>
    //       <FormLabel classes={{ root: classes.formLabelRoot }}>
    //         Organizations
    //       </FormLabel>
    //       <FormGroup>{organizationList}</FormGroup>
    //     </FormControl>
    //   );

    const Brands = () => (
      <TabContainer>
        <FormControl>
          <FormLabel classes={{ root: classes.formLabelRoot }}>
            Brands
          </FormLabel>
          <FormGroup>{brandList}</FormGroup>
        </FormControl>
        {productList.length > 0 && <Products />}
      </TabContainer>
    );

    const Products = () => (
      <FormControl style={{ marginTop: 16 }}>
        <FormLabel classes={{ root: classes.formLabelRoot }}>
          Products
        </FormLabel>
        <FormGroup>{productList}</FormGroup>
      </FormControl>
    );

    // TODO: Account List should be scrollable
    // const Entities = () => (
    //   <TabContainer>
    //     <FormControl>
    //       <FormLabel classes={{ root: classes.formLabelRoot }}>
    //         Entity
    //       </FormLabel>
    //       <RadioGroup
    //         name={'entityType'}
    //         value={entityType}
    //         onChange={this.onSelectEntityType}
    //       >
    //         <FormControlLabel
    //           label="Account"
    //           value="account"
    //           control={<Radio classes={{ root: classes.checkboxRoot }} />}
    //         />
    //         <FormControlLabel
    //           label="Organization"
    //           value="organization"
    //           control={<Radio classes={{ root: classes.checkboxRoot }} />}
    //         />
    //       </RadioGroup>
    //     </FormControl>
    //     {this.entityList()}
    //   </TabContainer>
    // );

    return (
      <Grid container direction="row" spacing={16}>
        <Grid item xs={12}>
          <Typography classes={{ root: classes.sectionLabel }}>
            Filters
          </Typography>
          <Tabs fullWidth value={activeTab} onChange={this.onSelectTab}>
            <Tab classes={{ root: classes.tabRoot }} icon={<DateRangeIcon />} />
            <Tab classes={{ root: classes.tabRoot }} icon={<CategoryIcon />} />
            <Tab classes={{ root: classes.tabRoot }} icon={<PeopleIcon />} />
          </Tabs>
          {activeTab === 0 && this.Timeframe()}
          {activeTab === 1 && <Brands />}
          {activeTab === 2 && this.entityType()}
        </Grid>
        <Grid item xs={12} style={{ padding: '0 24px' }} container spacing={8}>
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={this.checkLoader()}
              color="secondary"
              onClick={this.onSubmit}
              variant="contained"
            >
              <FilterListIcon fontSize="small" style={{ marginRight: 8 }} />
              Apply Filters
              {this.checkLoader() && (
                      <CircularProgress size={16} thickness={5} />
                    )}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              disabled={this.checkLoader()}
              fullWidth
              color="secondary"
              onClick={this.onClear}
              variant="outlined"
              style={{marginBottom: 20}}
            >
              <ClearIcon fontSize="small" style={{ marginRight: 8 }} />
              Clear Filters
              {this.checkLoader() && (
                      <CircularProgress size={16} thickness={5} />
                    )}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.filter,
  ui: state.ui
});

export default withStyles(styles)(withRouter(connect(mapStateToProps)(Filter)));


