import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { rematchContext } from '../../../common/models/index.model';

/**
 * Material UI - Core
 */
import {
  MenuList as MenuListCore,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
} from '@material-ui/core';

/**
 * Material UI - Icons
 */
import {
  HomeTwoTone as Home,
  PaymentTwoTone as Payment,
  PersonTwoTone as Person,
  LocalHospitalTwoTone as LocalHospital,
  InboxTwoTone as Inbox,
  ChevronRightTwoTone as ChevronRight,
} from '@material-ui/icons';

/**
 * Styled Component
 */
const MenuList = styled(MenuListCore)`
  .is-active {
    background-color: #ebebeb;
    font-weight: bold;
  }
`;
const MenuPopup = styled(Menu)`
  .is-active,
  .inactive:hover {
    background-color: #ebebeb !important;
    font-weight: bold;
  }
  .inactive:focus {
    background-color: white;
  }
`;

class Navigation extends React.Component {
  state = {
    anchorEl: {
      patients: null,
      doctors: null,
      products: null,
    },
    menu: [
      'summary',
      'patients',
      'enrolled-patients',
      'potentially-eligible-patients',
      'approved-patients',
      'doctors',
      'enrolled-doctors',
      'transacting-doctors',
      'products',
      'purchased-products',
      'redeemed-products',
      'transactions',
    ],
    activeMenu: 0,
  };

  componentDidMount() {
    const { menu } = this.state;
    const { pathname } = this.props.history.location;
    menu.forEach((value, index) => {
      if (pathname.includes(value)) {
        this._changeActiveMenu(index + 1);
      }
    });
  }

  _onLogout = async () => {
    await this.props.logoutUser();
  };

  _handleClick = (event, menu, activeMenu) => {
    let { anchorEl } = this.state;
    anchorEl[menu] = event.currentTarget;
    this.setState({ anchorEl });
    this._changeActiveMenu(activeMenu);
  };

  _handleClose = (menu, activeMenu) => {
    activeMenu && this._changeActiveMenu(activeMenu);
    let { anchorEl } = this.state;
    anchorEl[menu] = null;
    this.setState({ anchorEl });
  };

  _changeActiveMenu = activeMenu => {
    this.setState({ activeMenu });
  };

  render() {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { activeMenu } = this.state;
    let { patients, doctors, products } = this.state.anchorEl;
    return (
      <MenuList>
        <ListItem
          button
          component={NavLink}
          exact
          to={`/summary?start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
          onClick={() => this._changeActiveMenu(1)}
          className={activeMenu === 1 ? 'is-active' : null}
        >
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Summary" />
        </ListItem>

        {/*
         * Nav toggle for Patients
         */}
        <ListItem
          button
          aria-owns={patients ? 'patients' : undefined}
          aria-haspopup="true"
          onClick={e => this._handleClick(e, 'patients', 2)}
          className={activeMenu >= 2 && activeMenu <= 6 ? 'is-active' : null}
          style={{ paddingLeft: 32 }}
        >
          <ListItemIcon>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Patients" />
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
        </ListItem>
        <MenuPopup
          id="patients"
          anchorEl={patients}
          open={Boolean(patients)}
          onClose={() => this._handleClose('patients')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ListItem
            button
            component={NavLink}
            exact
            to={`/enrolled-patients?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('patients', 3)}
            className={activeMenu === 3 ? 'is-active' : 'inactive'}
          >
            <ListItemText primary="Enrolled Patients" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to={`/potentially-eligible-patients?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('patients', 5)}
            className={activeMenu === 5 ? 'is-active' : null}
          >
            <ListItemText primary="Potentially Eligible Patients" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to={`/approved-patients?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('patients', 6)}
            className={activeMenu === 6 ? 'is-active' : null}
          >
            <ListItemText primary="Approved Patients" />
          </ListItem>
        </MenuPopup>

        {/*
         * Nav toggle for Doctors
         */}
        <ListItem
          button
          aria-owns={doctors ? 'doctors' : undefined}
          aria-haspopup="true"
          onClick={e => this._handleClick(e, 'doctors', 7)}
          className={activeMenu >= 7 && activeMenu <= 9 ? 'is-active' : null}
          style={{ paddingLeft: 32 }}
        >
          <ListItemIcon>
            <LocalHospital />
          </ListItemIcon>
          <ListItemText primary="Doctors" />
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
        </ListItem>
        <MenuPopup
          id="doctors"
          anchorEl={doctors}
          open={Boolean(doctors)}
          onClose={() => this._handleClose('doctors')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ListItem
            button
            component={NavLink}
            exact
            to={`/enrolled-doctors?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('doctors', 8)}
            className={activeMenu === 8 ? 'is-active' : 'inactive'}
          >
            <ListItemText primary="Enrolled Doctors" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to={`/transacting-doctors?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('doctors', 9)}
            className={activeMenu === 9 ? 'is-active' : null}
          >
            <ListItemText primary="Transacting Doctors" />
          </ListItem>
        </MenuPopup>

        {/*
         * Nav toggle for Products
         */}
        <ListItem
          button
          aria-owns={products ? 'products' : undefined}
          aria-haspopup="true"
          onClick={e => this._handleClick(e, 'products', 10)}
          className={activeMenu >= 10 && activeMenu <= 12 ? 'is-active' : null}
          style={{ paddingLeft: 32 }}
        >
          <ListItemIcon>
            <Inbox />
          </ListItemIcon>
          <ListItemText primary="Products" />
          <ListItemIcon>
            <ChevronRight />
          </ListItemIcon>
        </ListItem>
        <MenuPopup
          id="products"
          anchorEl={products}
          open={Boolean(products)}
          onClose={() => this._handleClose('products')}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <ListItem
            button
            component={NavLink}
            exact
            to={`/purchased-products?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('products', 11)}
            className={activeMenu === 11 ? 'is-active' : 'inactive'}
          >
            <ListItemText primary="Purchased Products" />
          </ListItem>
          <ListItem
            button
            component={NavLink}
            exact
            to={`/redeemed-products?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
            onClick={() => this._handleClose('products', 12)}
            className={activeMenu === 12 ? 'is-active' : null}
          >
            <ListItemText primary="Redeemed Products" />
          </ListItem>
        </MenuPopup>
        <ListItem
          button
          component={NavLink}
          exact
          to={`/transactions?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`}
          onClick={() => this._changeActiveMenu(13)}
          className={activeMenu === 13 ? 'is-active' : null}
        >
          <ListItemIcon>
            <Payment />
          </ListItemIcon>
          <ListItemText primary="Transactions" />
        </ListItem>
      </MenuList>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToProps = ({ userStore, filterStore }) => {
  return {
    ...userStore,
    ...filterStore,
  };
};

export default withRouter(
  connect(
    mapStateToPropsRematch,
    mapDispatchToProps,
    null,
    { context: rematchContext }
  )(Navigation)
);
