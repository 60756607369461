import React from 'react';
import { connect } from 'react-redux';
import { rematchContext } from '../../models/index.model';
import queryString from 'query-string';
import * as formatters from '../../utilities/formatters.utility';

// Components
import { DataCard } from '../../components/Card/Card.component';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable.component';

const { DASHBOARD_TYPE } = window.config;

class TransactionPage extends React.Component {
  state = {
    previousSearch: '',
    currentPage: 1,
    columns: [
      {
        Header: 'Date and Time of Transaction',
        Cell: props => (
          <span>{formatters.date(props.original.transaction_date)}</span>
        ),
      },
      { Header: 'Transaction ID', accessor: 'transaction_batch_id' },
      { Header: `${DASHBOARD_TYPE === 'stayon'? 'Doctor' : 'HCP'} Name`, accessor: 'doctor_name' },
      { Header: `${DASHBOARD_TYPE === 'stayon'? 'Zuellig' : 'DKSH'} Number`, accessor: 'organization_code'},
      { Header: 'Clinic Name', accessor: 'clinic_name' },
      { Header: 'HCP Type', accessor: 'user_type', show: DASHBOARD_TYPE === 'mycare' ? true : false},
      { Header: 'Prescribing Physician', 
        Cell: props => (
          <span>
            {props.original.prescribing_physician || '--'}
          </span>
        ),
        show: DASHBOARD_TYPE === 'mycare' ? true : false
      },
      { Header: `Prescribing Physician's Clinic`, 
        Cell: props => (
          <span>
            {props.original.prescribing_clinic_name || '--'}
          </span>
        ), 
        show: DASHBOARD_TYPE === 'mycare' ? true : false
      },
      { Header: 'Patient ID', accessor: 'patient_id' },
      { Header: 'Promo Code Used', accessor: 'promo_code' },
      { Header: 'No. of Boxes', accessor: 'number_of_boxes' },
      {
        Header: 'Product',
        Cell: props => (
          <span>
            {props.original.sku &&
              props.original.sku.split(',').map(sku => {
                return <div key={sku}>{sku}</div>;
              })}
          </span>
        ),
        show: DASHBOARD_TYPE === 'mycare' ? true : false,
      },
      {
        Header: 'Meditags Used',
        Cell: props => (
          <span>
            {props.original.meditags_used && props.original.meditags_used.split(',').map(meditag => {
              return <div key={meditag}>{meditag}</div>;
            })}
          </span>
        ),
      },
      { Header: 'Redemption Code', accessor: 'promo_code' },
      { Header: 'Redemption Status', accessor: 'promo_code_status' },
    ],
  };

  async componentDidMount() {
    this.setState({ previousPath: this.props.location.pathname });
    await this._loadData();
  }

  async componentDidUpdate(prevProps) {
    if (this.state.previousSearch !== this.props.history.location.search) {
      this.setState({ previousSearch: this.props.history.location.search });
      await this._loadData();
    }
    if (
      prevProps.filterStore.filters.start_date !==
        this.props.filterStore.filters.start_date ||
      prevProps.filterStore.filters.end_date !==
        this.props.filterStore.filters.end_date ||
      prevProps.filterStore.filters.doctor !== this.props.filterStore.filters.doctor
    ) {
      await this._loadData();
    }
  }

  _loadData = async () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    const { pathname } = this.props.history.location;
    let params = queryString.parse(this.props.history.location.search) || {};
    await this.props.getTransactionList({
      ...params,
      start_date,
      end_date,
      doctor
    });
    if (
      params.page <= this.props.transactionStore.transactions.pages &&
      params.page > 0
    ) {
      this.setState({ currentPage: params.page || 1 });
    } else {
      this.props.history.push(
        `${pathname}?page=1&start_date=${start_date}&end_date=${end_date}${doctor ? `&doctor=${doctor}` : ``}`
      );
    }
  };

  _openExportModal = () => {
    const { start_date, end_date, doctor } = this.props.filterStore.filters;
    this.props.showPopupModal({
      title: 'Transaction History',
      message: 'Are you sure you want to export this table?',
      actions: [
        {
          label: 'Export',
          onClick: async () => {
            await this.props.exportTransactionList({
              start_date,
              end_date,
              doctor
            });
            this.props.closePopupModal();
          },
        },
      ],
    });
  };

  render() {
    const { columns, currentPage } = this.state;

    return (
      <DataCard
        title="Transaction History"
        style={{ background: '#8b1b3f', height: '100%' }}
        onExport={() => this._openExportModal()}
        dataCount={this.props.transactionStore.transactions.count}
      >
        <PaginatedTable
          data={this.props.transactionStore.transactions.list}
          columns={columns}
          totalPages={this.props.transactionStore.transactions.pages}
          currentPage={currentPage}
          history={this.props.history}
        />
      </DataCard>
    );
  }
}

// REMATCH
const mapStateToPropsRematch = state => state;
const mapDispatchToPropsRematch = ({
  transactionStore,
  filterStore,
  uiStore,
}) => {
  return {
    ...transactionStore,
    ...filterStore,
    ...uiStore,
  };
};

export default connect(
  mapStateToPropsRematch,
  mapDispatchToPropsRematch,
  null,
  { context: rematchContext }
)(TransactionPage);
