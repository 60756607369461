import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import '../../../config.js';

// COMMON COMPONENTS, CONTAINERS
import RequireAuthentication from '../../common/routers/AuthHOC';
import PopupAlert from '../../common/components/PopupAlert/PopupAlert.component';
import ErrorPage from '../../common/containers/ErrorPage/ErrorPage.container';
import LoginPage from '../../common/containers/LoginPage/LoginPage.container';
import Summary from '../../common/containers/SummaryPage/SummaryPage.container';
import Transaction from '../../common/containers/TransactionPage/TransactionPage.container';
import PotentiallyEligiblePatient from '../../common/containers/PotentiallyEligiblePatientPage/PotentiallyEligiblePatientPage.container';
import TransactingPatient from '../../common/containers/TransactingPatientPage/TransactingPatientPage.container';
import EnrolledPatient from '../../common/containers/EnrolledPatientPage/EnrolledPatientPage.container';
import ApprovedPatient from '../../common/containers/ApprovedPatientPage/ApprovedPatientPage.container';
import EnrolledDoctor from '../../common/containers/EnrolledDoctorPage/EnrolledDoctorPage.container';
import TransactingDoctor from '../../common/containers/TransactingDoctorPage/TransactingDoctorPage.container';
import PurchasedProduct from '../../common/containers/PurchasedProductPage/PurchasedProductPage.container';
import RedeemedProduct from '../../common/containers/RedeemedProductPage/RedeemedProductPage.container';
import PatientDetail from '../../common/containers/PatientDetailPage/PatientDetailPage.container';
import DoctorDetail from '../../common/containers/DoctorDetailPage/DoctorDetailPage.container';

const AppRouter = () => {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
      <PopupAlert />
      <BrowserRouter>
        <Switch>
          {/* Common */}
          <RequireAuthentication path="/" component={LoginPage} exact />
          <RequireAuthentication
            path="/summary"
            component={Summary}
            exact
          />
          <RequireAuthentication
            path="/potentially-eligible-patients"
            component={PotentiallyEligiblePatient}
            exact
          />
          <RequireAuthentication
            path="/transacting-patients"
            component={TransactingPatient}
            exact
          />
          <RequireAuthentication
            path="/enrolled-patients"
            component={EnrolledPatient}
            exact
          />
          <RequireAuthentication
            path="/approved-patients"
            component={ApprovedPatient}
            exact
          />
          <RequireAuthentication path="/transactions" component={Transaction} />
          <RequireAuthentication
            path="/enrolled-doctors"
            component={EnrolledDoctor}
            exact
          />
          <RequireAuthentication
            path="/transacting-doctors"
            component={TransactingDoctor}
            exact
          />
          <RequireAuthentication
            path="/purchased-products"
            component={PurchasedProduct}
          />
          <RequireAuthentication
            path="/redeemed-products"
            component={RedeemedProduct}
          />
          <RequireAuthentication
            path="/potentially-eligible-patients/:patient_id"
            component={PatientDetail}
            exact
          />
          <RequireAuthentication
            path="/approved-patients/:patient_id"
            component={PatientDetail}
            exact
          />
          <RequireAuthentication
            path="/enrolled-patients/:patient_id"
            component={PatientDetail}
            exact
          />
          <RequireAuthentication
            path="/transacting-patients/:patient_id"
            component={PatientDetail}
            exact
          />
          <RequireAuthentication
            path="/enrolled-doctors/:hcp_id"
            component={DoctorDetail}
            exact
          />
          <RequireAuthentication
            path="/transacting-doctors/:hcp_id"
            component={DoctorDetail}
            exact
          />
          <RequireAuthentication
            path="/enrolled-doctors/:hcp_id/patient/:patient_id"
            component={PatientDetail}
            exact
          />
          <RequireAuthentication
            path="/transacting-doctors/:hcp_id/patient/:patient_id"
            component={PatientDetail}
            exact
          />

          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default AppRouter;
