import React, { createRef } from 'react';
import { connect } from 'react-redux';

//validator
import { checkRangeDateDownloadExport } from '../../utils/date-validator';

// Components
import { LineGraph, MultiLineColumnGraph } from '../ui/Chart';
import { Grid, Button } from '@material-ui/core';
import { SummaryCard, DataCard } from '../ui/Card';
import AggregatedValue from '../ui/AggregatedValue';

// Actions
import {
  getPatientSummary,
  getMonthlyEnrolledPatients,
  getMonthlyTransactingPatients,
  getMonthlyRefillersAndNewPatients,
} from '../../actions/patient';

import { downloadPatientPageReport } from '../../actions/report';

// Utility
import {
  downloadChartImage,
  downloadFile,
  getChartImage,
} from '../../utils/download';

import Dialog from '../../components/ui/Dialog';

// Icons
import { SaveAltTwoTone as SaveAlt } from '@material-ui/icons';

//throws an error (super expression must either be null or a function) if import.
const Excel = require('exceljs');

class PatientPage extends React.Component {
  constructor(props) {
    super(props);

    this.monthlyEnrolledPatientsChart = createRef(null);
    this.monthlyTransactingPatientsChart = createRef(null);
    this.monthlyTransactingRefillersChart = createRef(null);
  }

  state = {
    patientDialog: false,
  }

  componentDidMount() {
    this.getSummary();
    this.getMonthlyEnrolledTimegraph();
    this.getMonthlyTransactingTimegraph();
    this.getMonthlyRefillersAndNewPatients();
  }

  downloadMonthlyEnrolledPatientsChartImage = () => {
    downloadChartImage(
      this.monthlyEnrolledPatientsChart.current.chart,
      'Monthly-Enrolled-Patients'
    );
  };

  downloadMonthlyTransactingPatientsChartImage = () => {
    downloadChartImage(
      this.monthlyTransactingPatientsChart.current.chart,
      'Monthly-Transacting-Patients'
    );
  };

  downloadMonthlyTransactingRefillersChartImage = () => {
    downloadChartImage(
      this.monthlyTransactingRefillersChart.current.chart,
      'Monthly-Transacting-Refillers-And-New-Patients'
    );
  };

  downloadPatientSummaryReport = async () => {
    const alignmentRight = { horizontal: 'right' },
      alignmentLeft = { horizontal: 'left' };
    const {
      totalEnrolledPatients,
      totalTransactingPatients,
      monthlyEnrolled,
      monthlyTransacting,
      monthlyTransactionRefillerAndPatient,
    } = this.props.patient;

    const {
      dateFrom,
      dateTo,
      selectedAccounts,
      selectedBrandIds,
    } = this.props.filter;

    const isRangeDateValid = checkRangeDateDownloadExport({
      start_date: dateFrom,
      end_date: dateTo,
    });

    if(!isRangeDateValid){
      this.setState({
        patientDialog: true
      })
      return;
    }

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Patients Summary', {
      views: [{ showGridLines: false }],
    });
    sheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    sheet.columns = [
      { alignment: alignmentLeft },
      { width: 30, alignment: alignmentLeft },
      { width: 25, alignment: alignmentRight },
    ];
    sheet.addRow([]);

    sheet.addRow(['', 'Filters Applied']);

    if (selectedAccounts && selectedAccounts.length === 0) {
      sheet
        .addRow(['', 'Account/Outlet', '-'])
        .getCell(3).alignment = alignmentRight;
    } else {
      selectedAccounts.forEach((el, index) => {
        if (index === 0)
          sheet
            .addRow(['', 'Account/Outlet', el])
            .getCell(3).alignment = alignmentRight;
        else sheet.addRow(['', '', el]).getCell(3).alignment = alignmentRight;
      });
    }

    sheet
      .addRow(['', 'Start Date', dateFrom])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow(['', 'End Date', dateTo])
      .getCell(3).alignment = alignmentRight;

    if (selectedBrandIds && selectedBrandIds.length === 0) {
      sheet.addRow(['', 'Brand', '-']).getCell(3).alignment = alignmentRight;
    } else {
      selectedBrandIds.forEach((el, index) => {
        if (index === 0)
          sheet.addRow(['', 'Brand', el]).getCell(3).alignment = alignmentRight;
        else sheet.addRow(['', '', el]).getCell(3).alignment = alignmentRight;
      });
    }

    sheet.addRow([]);
    sheet.addRow([]);

    sheet
      .addRow(['', 'Total Enrolled Patients', totalEnrolledPatients])
      .getCell(3).alignment = alignmentRight;
    sheet
      .addRow(['', 'Total Transacting Patients', totalTransactingPatients])
      .getCell(3).alignment = alignmentRight;
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Monthly Enrolled Patients']);

    sheet.addRow(['', 'Month', 'Enrolled Patients']);
    monthlyEnrolled.data.forEach(el => {
      let row = sheet.addRow(['', el.name, el.y]);
      row.alignment = alignmentRight;
    });
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Monthly Transacting Patients']);

    sheet.addRow(['', 'Month', 'Transacting Patients']);
    monthlyTransacting.data.forEach(el => {
      let row = sheet.addRow(['', el.name, el.y]);
      row.alignment = alignmentRight;
    });
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Monthly Transacting Refillers and New Patients']);

    sheet.addRow(['', 'Month', 'Transacting Refillers', 'New Patients']);

    const payload = [];
    monthlyTransactionRefillerAndPatient.reduce((acc, value) => {
      if (!acc[value.month]) {
        acc[value.month] = { month: value.month, new_patient: 0, refiller: 0 };
        payload.push(acc[value.month]);
      }

      acc[value.month].refiller += value.refiller;
      acc[value.month].new_patient += value.new_patient;
      return acc;
    }, {});

    const monthlyTransactionRefillerAndPatientResult = {
      month: payload.map(transaction => transaction.month),
      refiller: payload.map(transaction => transaction.refiller),
      newPatient: payload.map(transaction => transaction.new_patient),
    };

    monthlyTransactionRefillerAndPatientResult.month.forEach((el, index) => {
      let row = sheet.addRow([
        '',
        el,
        monthlyTransactionRefillerAndPatientResult.refiller[index],
        monthlyTransactionRefillerAndPatientResult.newPatient[index],
      ]);
      row.alignment = alignmentRight;
    });

    const buf = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buf]));

    downloadFile(url, 'Patients Summary.xlsx');
    window.URL.revokeObjectURL(url);
  };

  downloadPatientPageReport = async () => {
    const { dispatch } = this.props;

    const {
      totalEnrolledPatients,
      totalTransactingPatients,
      monthlyEnrolled,
      monthlyTransacting,
      monthlyTransactionRefillerAndPatient,
    } = this.props.patient;

    const {
      dateFrom,
      dateTo,
      selectedAccounts,
      selectedBrandIds,
    } = this.props.filter;

    const isRangeDateValid = checkRangeDateDownloadExport({
      start_date: dateFrom,
      end_date: dateTo,
    });

    if (!isRangeDateValid) {
      this.setState({
        patientDialog: true,
      });
      return;
    }

    const monthlyEnrolledPatientsBase64 = await getChartImage(
      this.monthlyEnrolledPatientsChart.current.chart
    );
    const monthlyTransactingPatientsBase64 = await getChartImage(
      this.monthlyTransactingPatientsChart.current.chart
    );
    const monthlyTransactingRefillersBase64 = await getChartImage(
      this.monthlyTransactingRefillersChart.current.chart
    );

    dispatch(
      downloadPatientPageReport({
        totalEnrolledPatients,
        totalTransactingPatients,
        monthlyEnrolled,
        monthlyTransacting,
        monthlyTransactionRefillerAndPatient,
        dateFrom,
        dateTo,
        selectedAccounts,
        selectedBrandIds,
        monthlyEnrolledPatientsBase64,
        monthlyTransactingPatientsBase64,
        monthlyTransactingRefillersBase64,
      })
    );
  };

  getSummary = () => {
    const { dispatch } = this.props;
    dispatch(getPatientSummary());
  };

  getMonthlyEnrolledTimegraph = () => {
    const { dispatch } = this.props;
    dispatch(getMonthlyEnrolledPatients());
  };

  getMonthlyTransactingTimegraph = () => {
    const { dispatch } = this.props;
    dispatch(getMonthlyTransactingPatients());
  };

  getMonthlyRefillersAndNewPatients = () => {
    const { dispatch } = this.props;
    dispatch(getMonthlyRefillersAndNewPatients());
  };

  createMonthlyRefillersAndNewPatientsGraphData = () => {
    const { availableBrands } = this.props.filter;
    const { monthlyTransactionRefillerAndPatient } = this.props.patient;
    const obj = {
      months: Array.from(
        new Set(monthlyTransactionRefillerAndPatient.map(el => el.month))
      ),
      data: [],
    };

    obj.months.forEach((monthEl, monthIndex) => {
      monthlyTransactionRefillerAndPatient
        .filter(transaction => transaction.month === monthEl)
        .map(({ brand_id, brand_name, new_patient, refiller }) => {
          let seriesNewPatients = obj.data.find(
            el => el.stack === brand_id && el.name.includes('New Patients')
          );
          let seriesRefillers = obj.data.find(
            el => el.stack === brand_id && el.name.includes('Refillers')
          );

          if (seriesNewPatients || seriesRefillers) {
            seriesNewPatients.data[monthIndex] = new_patient;
            seriesRefillers.data[monthIndex] = refiller;
          } else {
            let patientObject = {
              name: `${brand_name} New Patients`,
              // name: (availableBrands.length === 0
              //   ? brand_id
              //   : availableBrands.find(brand => brand.id === brand_id).name) +
              // ' New Patients',
              data: new Array(obj.months.length).fill(0),
              stack: brand_id,
            };

            let refillerObject = {
              name: `${brand_name} Refillers`,
              // name:
              //   (availableBrands.length === 0
              //     ? brand_id
              //     : availableBrands.find(brand => brand.id === brand_id).name) +
              //   ' Refillers',
              data: new Array(obj.months.length).fill(0),
              stack: brand_id,
            };

            patientObject.data[monthIndex] = new_patient;
            refillerObject.data[monthIndex] = refiller;
            obj.data.push(patientObject);
            obj.data.push(refillerObject);
          }
        });
    });

    return obj;
  };

  closeModal = () => {
    this.setState({ 
      patientDialog: false, 
    });
  }

  render() {
    const ui = this.props.ui;
    const {
      totalEnrolledPatients,
      totalTransactingPatients,
      totalVisitingPatients,
      averagePatientVisit,
      averageQuantityPerTransaction,
      monthlyEnrolled,
      monthlyTransacting,
    } = this.props.patient;

    const { patientDialog } = this.state;

    const { selectedBrandIds, availableBrands } = this.props.filter;

    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
        spacing={16}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="flex-end"
            spacing={10}
            style={{ marginBottom: 10 }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={this.downloadPatientPageReport}
            >
              <SaveAlt fontSize="small" style={{ marginRight: 6 }} /> Export All
              To Excel
            </Button>
          </Grid>
          <SummaryCard
            title="Patient Summary"
            subtitle="General information"
            actionButton={{
              text: 'Export Excel',
              iconComponent: (
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
              ),
              action: this.downloadPatientSummaryReport,
            }}
          >
            <AggregatedValue
              value={totalEnrolledPatients}
              description="Total Enrolled Patients"
              isLoading={ui.patientSummary.isLoading}
            />
            <AggregatedValue
              value={totalTransactingPatients}
              description="Total Transacting Patients"
              isLoading={ui.patientSummary.isLoading}
            />
            <AggregatedValue
              value={totalVisitingPatients}
              description="Total Refillers"
              tooltip="Patients who visited the program more than once"
              isLoading={ui.patientSummary.isLoading}
            />

            <AggregatedValue
              value={averagePatientVisit}
              description="Average Visits"
              isLoading={ui.patientSummary.isLoading}
            />
            <AggregatedValue
              value={averageQuantityPerTransaction}
              description="Average Quantity in Boxes"
              tooltip="Purchased per transaction"
              isLoading={ui.patientSummary.isLoading}
            />
          </SummaryCard>
        </Grid>

        <Grid xs={12} item>
          <DataCard
            isLoading={ui.monthlyEnrolledPatients.isLoading}
            title="Monthly Enrolled Patients"
            subtitle="Number of new patients enrolled per month"
            actionButton={{
              text: 'Export Image',
              iconComponent: (
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
              ),
              action: this.downloadMonthlyEnrolledPatientsChartImage,
            }}
          >
            <LineGraph
              filename="Monthly Enrolled Patients"
              ref={this.monthlyEnrolledPatientsChart}
              series={monthlyEnrolled}
              yAxis="Total Patients"
              xAxis="Period"
            />
          </DataCard>
        </Grid>

        <Grid xs={12} item>
          <DataCard
            isLoading={ui.monthlyTransactingPatients.isLoading}
            title="Monthly Transacting Patients"
            subtitle="Number of patients transacting per month"
            actionButton={{
              text: 'Export Image',
              iconComponent: (
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
              ),
              action: this.downloadMonthlyTransactingPatientsChartImage,
            }}
          >
            <LineGraph
              filename="Monthly Transacting Patients"
              ref={this.monthlyTransactingPatientsChart}
              series={monthlyTransacting}
              yAxis="Total Patients"
              xAxis="Period"
            />
          </DataCard>
        </Grid>
        <Grid xs={12} item>
          <DataCard
            isLoading={ui.monthlyTransactionRefillersAndNewPatients.isLoading}
            title="Monthly Transacting Refillers And New Patients"
            actionButton={{
              text: 'Export Image',
              iconComponent: (
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
              ),
              action: this.downloadMonthlyTransactingRefillersChartImage,
            }}
          >
            <MultiLineColumnGraph
              ref={this.monthlyTransactingRefillersChart}
              title={
                selectedBrandIds.length !== 0
                  ? selectedBrandIds.length > 1
                    ? 'Monthly Transacting For Selected Products'
                    : `Monthly Transacting For ${availableBrands &&
                        availableBrands.find(
                          brand => brand.id === selectedBrandIds[0]
                        ).name} Patients`
                  : 'Monthly Transacting For All Products'
              }
              filename="Monthly Transacting Refillers And New Patients"
              series={this.createMonthlyRefillersAndNewPatientsGraphData()}
              yAxis="Total Patients"
              xAxis="Period"
            />
          </DataCard>
        </Grid>
        <Dialog
                open={patientDialog}
                content={"Please set your range date up to 1 year only when exporting"}>
                <Button 
                    onClick={() => this.closeModal()} 
                    color='primary' 
                    variant='contained'>
                    Close
                </Button>
            </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  filter: state.filter,
  ui: state.ui,
  patient: state.patient,
});

export default connect(mapStateToProps)(PatientPage);
