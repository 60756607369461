import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

import { Card } from './Card';
import {
    Done as DoneIcon,
} from '@material-ui/icons';
import { Typography, Chip, withStyles } from '@material-ui/core';
import { filterStyles, DateRange } from './Filters';

const formatDate = date => moment(date).format('MMMM DD, YYYY');

class ActiveFilters extends React.Component {
   
    getBrandName = brandId => {
        const { availableBrands } = this.props.filter;
        const findBrand = availableBrands.find(brand => brand.id === brandId);
        return findBrand ? findBrand.name : '';
    }

    getProductName = productId => {
        const { availableProducts } = this.props.filter;
        const findProduct = availableProducts.find(product => product.id === productId);
        return findProduct ? findProduct.name : '';
    }

    getAccountName = accountNumber => {
        const { retrievedAccounts } = this.props.filter;
        const findAccount = retrievedAccounts.find(account => account.account_number === accountNumber);
        return findAccount ? findAccount.account_name : '';
    }

    getTimeframe = (dateFrom, dateTo) => {
        const { classes } = this.props;
        return <Fragment>
            <Typography className={classes.sectionHeader}>Selected Timeframe</Typography>

            <div className={classes.section}>
                <DateRange label='Start Date' value={formatDate(dateFrom)} />
            </div>

            <div className={classes.section}>
                <DateRange label='End Date' value={formatDate(dateTo)} />
            </div>
        </Fragment>;
    }

    getBrands = selectedBrandIds => {
        const { classes } = this.props;
        if (selectedBrandIds.length > 0)
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Brands:</Typography> 
                {selectedBrandIds.map(brandId => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={this.getBrandName(brandId)} />)}
            </div>;
        else 
            return null;
    }

    getProducts = selectedProductIds => {
        const { classes } = this.props;
        if (selectedProductIds.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Products:</Typography>
                {selectedProductIds.map(productId => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={this.getProductName(productId)} />)}
            </div>;
        else 
            return null;
    }

    //TODO: reuse functions
    getAccounts = selectedAccounts => {
        const { classes } = this.props;
        if (selectedAccounts.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Accounts</Typography>
                {selectedAccounts.map(accountNumber => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={this.getAccountName(accountNumber)} />)}
            </div>;
        else 
            return null;
    }

    getOrganizations = selectedOrganizations => {
        const { classes } = this.props;
        if (selectedOrganizations.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Organizations:</Typography>
                {selectedOrganizations.map(branch => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={branch} />)}
            </div>;
        else 
            return null;
    }

    getZuelligAccountName = selectedZuelligAccountName => {
        const { classes } = this.props;
        if (selectedZuelligAccountName.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Zuellig Account Name:</Typography>
                {selectedZuelligAccountName.map(branch => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={branch} />)}
            </div>;
        else 
            return null;
    }

    getAccountType = selectedAccountType => {
        const { classes } = this.props;
        if (selectedAccountType) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Account Type:</Typography>
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={selectedAccountType} />
            </div>;
        else 
            return null;
    }

    getZuellingAccountGroupName = selectedZuelligAccountGroupName => {
        const { classes } = this.props;
        if (selectedZuelligAccountGroupName.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Account Group Name:</Typography>
                {selectedZuelligAccountGroupName.map(branch => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={branch} />)}
            </div>;
        else 
            return null;
    }

    getSalesExecutive = selectedSalesExecutive => {
        const { classes } = this.props;
        if (selectedSalesExecutive.length > 0) 
            return <div className={classes.section}>
                <Typography className={classes.sectionHeader}>Selected Sales Executive:</Typography>
                {selectedSalesExecutive.map(branch => 
                    <Chip 
                        color='secondary'
                        variant='outlined'
                        className={classes.chip}
                        icon={<DoneIcon />}
                        label={branch} />)}
            </div>;
        else 
            return null;
    }

    render() {
        const { classes, filter } = this.props;
        const {
          dateFrom,
          dateTo,
          selectedBrandIds,
          selectedProductIds,
          selectedAccounts,
          selectedOrganizations,
          selectedZuelligAccountName,
          selectedAccountType,
          selectedZuelligGroupName,
          selectedSalesExecutive,
        } = filter;

        return (
            <Fragment>
                
                    <Card cardClassName={classes.card}>
                        <Typography className={classes.cardHeader}>Active Filters</Typography>
                        <br/>
                        
                        {this.getTimeframe(dateFrom, dateTo)}
                        {this.getBrands(selectedBrandIds)}
                        {this.getProducts(selectedProductIds)}
                        {this.getAccounts(selectedAccounts)}
                        {this.getOrganizations(selectedOrganizations)}
                        {this.getZuelligAccountName(selectedZuelligAccountName)}
                        {this.getAccountType(selectedAccountType)}
                        {this.getZuellingAccountGroupName(selectedZuelligGroupName)}
                        {this.getSalesExecutive(selectedSalesExecutive)}
                    </Card>
            </Fragment>
        );
    }
};

const mapStateToProps = state => ({
    filter: state.filter
});

export default withStyles(filterStyles)(connect(mapStateToProps)(ActiveFilters));