import Request from './request';

const route = 'products';

export default class Product {
    static getTotalQuantitySold = async (filters, userType) => {
        userType = userType || '';

        filters.userType = userType;

        try {
            const response = await Request.get(`/${route}/total-quantity-sold`, filters);
            return response.data 
                && Object.keys(response.data).length > 0 
                ? response.data
                : {};
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getTotalTransactions = async (filters, userType) => {
        userType = userType || '';

        filters.userType = userType;
        
        try {
            const response = await Request.get(`/${route}/total-transactions`, filters);
            const total = response.data 
                && Object.keys(response.data).length > 0 
                ? response.data
                : {};

            return {
                totalTransactionBatches: total.total && total ? total.total.total_batch : 0,
                totalTransactionCampaigns: total.total && total ? total.total.total_campaign : 0,
                totalTransactionBatchesBox: total.box && total ? total.box.total_batch : 0,
                totalTransactionCampaignsBox: total.box && total ? total.box.total_campaign : 0,
                totalTransactionBatchesStrip: total.strips && total ? total.strips.total_batch : 0,
                totalTransactionCampaignsStrip: total.strips && total ? total.strips.total_campaign : 0,
            };
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getAverageQuantity = async (filters) => {
        try {
            const response = await Request.get(`/${route}/average-quantity`, filters);
            const averageQty = response.data.average_quantity ? parseFloat(response.data.average_quantity) : 0;
            return Math.floor(averageQty);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getBrandMix = async (filters) => {
        try {
            return await Request.get(`/${route}/brand-mix`, filters);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getMonthlyTransactions = async (filters) => {
        try {
            const response = await Request.get(`/${route}/timegraph`, filters);
            return response.data.monthly;
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getTransactionFeed = async (filters, limit) => {
        try {
            return await Request.get(`/${route}/transaction-feed`, filters, { limit });
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getRegistrationTransactionReport = async (filters, skip) => {
        try {
            return await Request.get(`/${route}/registration-transaction-report`, filters, { skip });
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getRegistrationTransactionSummary = async (filters) => {
        try {
            return await Request.get(`/${route}/registration-transaction-summary`, filters);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
    
    static getRebate = async (filters) => {
        try{
            return await Request.get(`/${route}/rebate`, filters);
        }
        catch(e){
            throw new Error(e.message);
        }
    }

    static getBoxes = async (filters) => {
        try{
            return await Request.get(`/${route}/boxes`, filters);
        }
        catch(e){
            throw new Error(e.message);
        }
    }
    
    static getBrandTimegraph = async (filters) => {
        try {
            return await Request.get(`/${route}/brand-timegraph`, filters);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
}