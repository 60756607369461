import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Drawer, Divider, withStyles, Grid } from '@material-ui/core';
import Nav from './_main/Nav';
import Filter from './_main/Filter';
import ActiveFiltersCard from '../ui/ActiveFilters';

const MTHPLogo = 'https://s3-ap-southeast-1.amazonaws.com/connect-my-pfizer-production/assets/images/mthp-logo-white-2.png';

const styles = theme => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%',
        position: 'relative',
        zIndex: 1
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    drawerPaper: {
        position: 'relative',
        width: 260,
        minHeight:800,
        overflowX: 'hidden',
        '&::-webkit-scrollbar':{
            width: 0
        }
    },
    content: {
        backgroundColor: theme.palette.background.default,
        flexGrow: 1,
        minWidth: 0, // So the Typography noWrap works
        padding: theme.spacing.unit * 3
    },
    toolbar: theme.mixins.toolbar,
});

class MainPage extends Component {
    state = {
        showActiveFiltersCard: true,
        activePath: ''
    }

    componentDidMount(){
        this.checkPathName();
    }

    componentDidUpdate(prevProps){
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.checkPathName();
        }
        
    }

    checkPathName(){
        let { activePath } = this.state;
        let pathName = this.props.history.location.pathname;
        
        if ( 
            pathName === '/patient-compliance' ||
            pathName === '/refill-patient-outlet-transaction' &&
            activePath !== pathName
        ) {
            this.setState({
                activePath: pathName,
                showActiveFiltersCard: false
            })
        } else if(activePath !== pathName){

            this.setState({
                activePath: pathName,
                showActiveFiltersCard: true
            });
        }
    }

    render(){
       const { classes, children } = this.props;

       return (
        <div className={classes.root}>
        <AppBar position="absolute" className={classes.appBar}>
            <Toolbar>
                <img src={MTHPLogo} alt='MyTotalHealth Logo' width={70} />
            </Toolbar>
        </AppBar>
        <Drawer
            variant='permanent'
            classes={{ paper: classes.drawerPaper }}>
            <div className={classes.toolbar} />
            <Divider />
            <Nav />
            <Divider />
            {this.state.showActiveFiltersCard && (
                <Filter />
            )}

        </Drawer>

        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container spacing={16}>
               
                {this.state.showActiveFiltersCard ? (
                    <Fragment>
                        <Grid item xs={9}>
                            {children}
                        </Grid>
    
                        <Grid item xs={3}>
                            <ActiveFiltersCard />
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid item xs={12}>
                        {children}
                    </Grid>
                )}
            </Grid>
        </main>
    </div>
       )
    }
}

export default withStyles(styles, { withTheme: true })(withRouter(MainPage));