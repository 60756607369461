const EXPORT_WIDTH = 1000;

const svgToDataImage = ({
  chart,
  filename = "",
  type = ""
}) => {

  let render_width = EXPORT_WIDTH;
  let render_height = render_width * chart.chartHeight / chart.chartWidth

  // Get the cart's SVG code
  let svg = chart.getSVG({
    exporting: {
      sourceWidth: chart.chartWidth,
      sourceHeight: chart.chartHeight
    }
  });

  // Create a canvas
  let canvas = document.createElement('canvas');
  canvas.height = render_height;
  canvas.width = render_width;
  document.body.appendChild(canvas);

  // Create an image and draw the SVG onto the canvas
  let image = new Image;
  image.onload = function() {
    canvas.getContext('2d').drawImage(this, 0, 0, render_width, render_height);
    let dataImage = canvas.toDataURL("image/png");

    if(type === "download"){
      downloadFile(dataImage, filename + '.png');
    }

  };
  image.src = 'data:image/svg+xml;base64,' + window.btoa(unescape(encodeURIComponent(svg)));
  return image.src
}

export const downloadFile = function (data, filename) {
    let a = document.createElement('a');
    a.download = filename;
    a.href = data
    document.body.appendChild(a);
    a.click();
    a.remove();
};

export const downloadChartImage = async (chart, filename) => {
   await svgToDataImage({
     chart,
     filename,
     type: "download"
   });
};

export const getChartImage = async chart => {
  let dataImage = await svgToDataImage({chart});
  return dataImage;
}