import moment from 'moment';


export const checkRangeDateDownloadExport = ({ start_date, end_date }) => {
    const monthDiff = moment(end_date).diff(start_date, 'months');

    if(monthDiff > 12){
        return false;
    }

    return true;
}