// Classes
import Filters from '../../classes/filter';

// Action Creators
import { 
    addFilters, resetFilters, 
    retrieveFilters, retrieveProducts 
} from './action-creators';
import { 
    getPatientSummary, 
    getMonthlyEnrolledPatients, 
    getMonthlyTransactingPatients,
    getMonthlyRefillersAndNewPatients
} from '../patient';
import { 
    getProductSummary, 
    getBrandMix, 
    getMonthlyTransactions, 
    getTransactionFeed,
    getRetailerRebateAndBoxes
} from '../product';
import { 
    getPharmacySummary, getPhysicianSummary, 
    getAccountLeaderboard, getOrganizationLeaderboard, 
    getAccountSummary, getOrganizationSummary, getBrandTimegraph
} from '../hcp';
import { getSummaries } from '../program';
import { apiRequestStart, apiRequestEnd } from '../ui';

export const applyFilters = (data, location) => {
    return async (dispatch, getState) => {
        // Apply the filters
        dispatch(addFilters(data));
        let userType = 'pharmacist';
        // Get the current route
        switch (location.pathname) {
            case '/': // Home Page
                dispatch(getSummaries());
                dispatch(getBrandTimegraph());
                dispatch(getTransactionFeed({ limit: 100 }));
                break;
            case '/products':
                dispatch(getProductSummary());
                dispatch(getBrandMix());
                dispatch(getMonthlyTransactions());
                dispatch(getTransactionFeed({ limit: 1000 }));
                dispatch(getRetailerRebateAndBoxes());
                break;
            case '/patients':
                dispatch(getPatientSummary());
                dispatch(getMonthlyEnrolledPatients());
                dispatch(getMonthlyTransactingPatients());
                dispatch(getMonthlyRefillersAndNewPatients());
                break;
            case '/pharmacies-summary':
                userType = 'pharmacist';
                dispatch(getPharmacySummary(userType));
                break;
            case '/pharmacies-account':
                userType = 'pharmacist';
                dispatch(getAccountLeaderboard(userType));
                dispatch(getAccountSummary(userType));
                break;
            case '/pharmacies-organization':
                userType = 'pharmacist';
                dispatch(getOrganizationLeaderboard(userType));
                dispatch(getOrganizationSummary(userType));
                break;
            case '/physicians':
                userType = 'physician';
                dispatch(getPhysicianSummary(userType));
                dispatch(getAccountLeaderboard(userType));
                dispatch(getOrganizationLeaderboard(userType));
                dispatch(getAccountSummary(userType));
                dispatch(getOrganizationSummary(userType));
                break;
            default:
                break;
        }
    };
};

export const clearFilters = location => {
    return async dispatch => {
        // Reset the filters
        dispatch(resetFilters());
        let userType = 'pharmacist';

        // Get the current route
        switch (location.pathname) {
            case '/': // Home Page
                dispatch(getSummaries());
                dispatch(getBrandTimegraph());
                dispatch(getTransactionFeed({ limit: 100 }));
                break;
            case '/products':
                dispatch(getProductSummary());
                dispatch(getBrandMix());
                dispatch(getMonthlyTransactions());
                dispatch(getTransactionFeed({ limit: 1000 }));
                dispatch(getRetailerRebateAndBoxes());
                break;
            case '/patients':
                dispatch(getPatientSummary());
                dispatch(getMonthlyEnrolledPatients());
                dispatch(getMonthlyTransactingPatients());
                dispatch(getMonthlyRefillersAndNewPatients());
                break;
            case '/pharmacies-summary':
                userType = 'pharmacist';
                dispatch(getPharmacySummary(userType));
                break;
            case '/pharmacies-account':
                userType = 'pharmacist';
                dispatch(getAccountLeaderboard(userType));
                dispatch(getAccountSummary(userType));
                break;
            case '/pharmacies-organization':
                userType = 'pharmacist';
                dispatch(getOrganizationLeaderboard(userType));
                dispatch(getOrganizationSummary(userType));
                break;
            case '/physicians':
                userType = 'physician';
                dispatch(getPhysicianSummary(userType));
                dispatch(getAccountLeaderboard(userType));
                dispatch(getOrganizationLeaderboard(userType));
                dispatch(getAccountSummary(userType));
                dispatch(getOrganizationSummary(userType));
                break;
            default:
                break;
        }
    };
};

export const getFilters = () => {
    return async dispatch => {
        // Start loading
        dispatch(apiRequestStart('brands'));
        dispatch(apiRequestStart('accounts'));
        dispatch(apiRequestStart('organizations'));
        dispatch(apiRequestStart('zuelligAccount'));
        dispatch(apiRequestStart('zuelligGroup'));
        dispatch(apiRequestStart('salesExecutive'));

        try {
            const brands = await Filters.getBrands();
            const accounts = await Filters.getAccounts();
            const organizations = await Filters.getOrganizations();
            const zuelligAccountName = await Filters.getZuelligAccountName();
            const zuelligAccountGroupName = await Filters.getZuelligAccountGroup();
            const salesExecutiveName = await Filters.getSalesExecutive();

            dispatch(retrieveFilters({
                brands,
                accounts,
                organizations,
                zuelligAccountName,
                zuelligAccountGroupName,
                salesExecutiveName
            }));
        }
        catch (e) {
            console.error(e);
        }

        // Stop loading
        dispatch(apiRequestEnd('brands'));
        dispatch(apiRequestEnd('accounts'));
        dispatch(apiRequestEnd('organizations'));
        dispatch(apiRequestStart('zuelligAccount'));
        dispatch(apiRequestStart('zuelligGroup'));
        dispatch(apiRequestStart('salesExecutive'));
    };
};

export const getProducts = selectedBrandIds => {
    return async dispatch => {
        if (selectedBrandIds && !(selectedBrandIds instanceof Array))
            throw new Error('Selected brand ids is not an array');

        // Start loading
        dispatch(apiRequestStart('products'));

        try {
            const products = await Filters.getProducts(selectedBrandIds);
            dispatch(retrieveProducts(products));
        }
        catch (e) {
            console.error(e);
        }

        // Stop loading
        dispatch(apiRequestEnd('products'));
    };
};