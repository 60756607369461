import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Components
import { 
    Grid, withStyles,
    List, ListItem, ListItemText
} from '@material-ui/core';
import { SummaryCard, DataCard } from '../ui/Card';
import AggregatedValue from '../ui/AggregatedValue';
import { Typography } from '@material-ui/core';

// Action Creators
import { getSummaries } from '../../actions/program';
import { getTransactionFeed } from '../../actions/product';
import { getBrandTimegraph } from '../../actions/hcp';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const dateFormat = 'YYYY-MMM-DD, h:mma';
moment.tz.setDefault('Asia/Kuala_Lumpur');

class HomePage extends React.Component {
    interval = null;
    componentDidMount() {
        this.getSummaries();
        this.getBrandTimegraph();
        this.getTransactionFeed();
    }
    
    componentWillUnmount(){
        clearInterval(this.interval);
    }

    getSummaries = () => {
        const { dispatch } = this.props;
        dispatch(getSummaries());
    }

    getTransactionFeed = () => {
        const { dispatch } = this.props;
        const limit = 100;
        const loadTime = 2;

        dispatch(getTransactionFeed(limit));

        // Fetch values every 2 minutes (depends on loadTime value)
        this.interval = setInterval(() => {
            dispatch(getTransactionFeed(limit));
        }, loadTime * 60000);
    }

    getBrandTimegraph = () => {
        const { dispatch } = this.props;
        dispatch(getBrandTimegraph());
    }

    displayTransactionFeed = transactionFeed => {
        return transactionFeed.map((transaction, index) => {
            const quantity = transaction.quantity;
            const unit = quantity === 1 ? 'box' : 'boxes';

            return <ListItem 
                        divider 
                        dense 
                        disableGutters 
                        key={index}>
                <ListItemText
                    inset={false}
                    primary={moment(transaction.transaction_date).format(dateFormat)}
                    secondary={`
                        ${transaction.product} (${quantity} ${unit}) 
                        
                        - DISCOUNT: RM ${(transaction.discount_amount ? transaction.discount_amount : 0).toFixed(2)}; 
                        
                        Sold by ${transaction.healthcare_professional}
                    `} />
            </ListItem>;
        });
    }

    displayBrandTimeGraph = (brandTimegraph, filter) => {
        if (brandTimegraph.length === 0) { 
            return <Typography>No data found</Typography>; 
        }
        const { selectedBrandIds, availableBrands } = filter;
        const chartOptions = {
            chart: {
                renderTo: 'container',
                type: 'line'
            },
            title: {
                text: 'Product-Quantity vs Time'
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: {
                    text: 'Product Quantity'
                }
            },
            plotOptions: {
                series: {
                    // general options for all series,
                },
            },
            series: []
        };

        if(selectedBrandIds.length === 0){
            availableBrands.forEach(({ name }) => chartOptions.series.push({ name: name, data: [] }));
        }
        else{
            selectedBrandIds.forEach( selectedId => {
                availableBrands.forEach(({ id, name }) => {
                    if(id === selectedId) chartOptions.series.push({ name: name, data: [] });
                });
            });
        }

        brandTimegraph.forEach(el => {
            chartOptions.xAxis.categories.push(el.month);
            chartOptions.series.forEach(seriesEl => {
                seriesEl.data.push(parseInt(el[seriesEl.name]));
            });
        });
        
        return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
    }
    
    render() {
        // Main Props
        const { ui, patient, product, hcp, classes, filter } = this.props;

        const { totalTransactingPatients } = patient;

        const { 
            totalEnrolledAccounts, 
            totalEnrolledOrganizations,
            totalTransactingAccounts, 
            totalTransactingOrganizations,
            totalTransactingHCPs, 
            totalEnrolledHCPs,
            pharmacy,
            physician,
            brandTimegraph
        } = hcp;

        const { 
            totalQuantitySold, 
            totalTransactionBatches, 
            totalTransactionCampaigns,
            transactionFeed
        } = product;

        const {
            totalEnrolledPharmacyAccounts,
            totalTransactingPharmacyAccounts,
            totalEnrolledPharmacyOrganizations,
            totalTransactingPharmacyOrganizations,
            totalEnrolledPharmacists,
            totalTransactingPharmacists,
        } = pharmacy;

        const {
            totalEnrolledPhysicianAccounts,
            totalTransactingPhysicianAccounts,
            totalEnrolledPhysicianOrganizations,
            totalTransactingPhysicianOrganizations,
            totalEnrolledPhysicians,
            totalTransactingPhysicians,
        } = physician;

        return <Grid spacing={16} container>
            <Grid item xs={12}>
                <SummaryCard 
                    title='Program Summary'
                    subtitle='General information about the program'>
                    
                    <AggregatedValue 
                        value={totalEnrolledAccounts} 
                        description='Total Enrolled Accounts'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingAccounts} 
                        description='Total Transacting Accounts'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingPatients} 
                        description='Total Transacting Patients'
                        isLoading={ui.programSummary.isLoading} />

                    <AggregatedValue 
                        value={totalEnrolledOrganizations} 
                        description='Total Enrolled Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingOrganizations} 
                        description='Total Transacting Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactionBatches} 
                        description='Total Transaction Batches'
                        isLoading={ui.programSummary.isLoading} />

                    <AggregatedValue 
                        value={totalEnrolledHCPs} 
                        description='Total Enrolled HCPs'
                        tooltip='Enrolled Health Care Professionals, both Pharmacies and Physicians'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactingHCPs} 
                        description='Total Transacting HCPs'
                        tooltip='Transacting Health Care Professionals, both Pharmacies and Physicians'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalTransactionCampaigns} 
                        description='Total Transaction Campaigns'
                        isLoading={ui.programSummary.isLoading} />

                    <AggregatedValue 
                        value={totalQuantitySold && totalQuantitySold.box}
                        valueInBoxes={true}
                        description='Total Boxes Sold'
                        isLoading={ui.programSummary.isLoading} />
                    <AggregatedValue 
                        value={totalQuantitySold && totalQuantitySold.strips}
                        valueInBoxes={true}
                        description='Total Strips Sold'
                        isLoading={ui.programSummary.isLoading} />
                </SummaryCard>
            </Grid>


            <Grid item xs={12}>
                <DataCard
                    title='Products Timegraph'
                    subtitle='General time-graph about the program'
                    isLoading={ui.brandTimegraph.isLoading}>
                    {this.displayBrandTimeGraph(brandTimegraph, filter)}
                </DataCard>
            </Grid>

            <Grid item xs={12} md={6}>
                <SummaryCard 
                    title='Pharmacy Summary'
                    subtitle='General information about pharmacies'>
                    
                    <AggregatedValue
                        md={6} 
                        value={totalEnrolledPharmacyAccounts} 
                        description='Total Enrolled Accounts'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPharmacyAccounts} 
                        description='Total Transacting Accounts'
                        isLoading={ui.pharmacySummary.isLoading} />
                    
                    <AggregatedValue 
                        md={6} 
                        value={totalEnrolledPharmacyOrganizations} 
                        description='Total Enrolled Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPharmacyOrganizations} 
                        description='Total Transacting Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.pharmacySummary.isLoading} />

                    <AggregatedValue 
                        md={6} 
                        value={totalEnrolledPharmacists} 
                        description='Total Enrolled Pharmacists'
                        isLoading={ui.pharmacySummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPharmacists} 
                        description='Total Transacting Pharmacists'
                        isLoading={ui.pharmacySummary.isLoading} />
                </SummaryCard>
            </Grid>
        
            <Grid item xs={12} md={6}>
                <SummaryCard 
                    title='Physician Summary'
                    subtitle='General information about physicians'>
                    
                    <AggregatedValue
                        md={6} 
                        value={totalEnrolledPhysicianAccounts} 
                        description='Total Enrolled Accounts'
                        isLoading={ui.physicianSummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPhysicianAccounts} 
                        description='Total Transacting Accounts'
                        isLoading={ui.physicianSummary.isLoading} />
                    
                    <AggregatedValue 
                        md={6} 
                        value={totalEnrolledPhysicianOrganizations} 
                        description='Total Enrolled Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.physicianSummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPhysicianOrganizations} 
                        description='Total Transacting Organizations'
                        tooltip='Outlets / Branches'
                        isLoading={ui.physicianSummary.isLoading} />

                    <AggregatedValue 
                        md={6} 
                        value={totalEnrolledPhysicians} 
                        description='Total Enrolled Physicians'
                        isLoading={ui.physicianSummary.isLoading} />
                    <AggregatedValue 
                        md={6} 
                        value={totalTransactingPhysicians} 
                        description='Total Transacting Physicians'
                        isLoading={ui.physicianSummary.isLoading} />
                </SummaryCard>
            </Grid>

            <Grid item xs={12}>
                <DataCard
                    cardContentClassName={classes.root}
                    title='Transaction Feed'
                    subtitle='Latest 100 transactions'
                    isLoading={ui.transactionFeed.isLoading}>

                    <List dense disablePadding>
                        {transactionFeed.length > 0 ? 
                            this.displayTransactionFeed(transactionFeed)
                        : null}
                    </List>
                </DataCard>
            </Grid>
        </Grid>;
    }
};

const mapStateToProps = state => ({
    filter: state.filter,
    ui: state.ui,
    product: state.product,
    hcp: state.hcp,
    patient: state.patient
});

const styles = () => ({
    root: {
        height: 410,
        overflowY: 'scroll'
    }
});

export default withStyles(styles)(connect(mapStateToProps)(HomePage));