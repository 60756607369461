// Classes
import Product from '../../classes/product';
import HCP from '../../classes/hcp';

// Actions
import { 
    displayProductError, 
    displayProductSummary, 
    displayBrandMix, 
    displayMonthlyTransactions, 
    displayTransactionFeed, 
    displayRegistrationTransactionSummary, 
    displayRegistrationTransactionReport,
    displayRetailerRebateAndBoxes
} from './action-creators';
import { apiRequestStart, apiRequestEnd } from '../ui';
import { displayHCPSummary } from '../hcp/action-creators';

export const getProductSummary = () => {
    return async (dispatch, getState) => {
        const trigger = 'productSummary';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const totalQuantitySold = await Product.getTotalQuantitySold(filters);
            const totalTransactions = await Product.getTotalTransactions(filters);
            const averageQuantity =  {};

            averageQuantity.box =
              totalQuantitySold.box /
                totalTransactions.totalTransactionCampaignsBox ===
              Infinity
                ? 0
                : isNaN(
                    totalQuantitySold.box /
                      totalTransactions.totalTransactionCampaignsBox
                  )
                ? 0
                : totalQuantitySold.box /
                  totalTransactions.totalTransactionCampaignsBox;
            averageQuantity.strip =
              totalQuantitySold.strips /
                totalTransactions.totalTransactionCampaignsStrip ===
              Infinity
                ? 0
                : isNaN(
                    totalQuantitySold.strips /
                      totalTransactions.totalTransactionCampaignsStrip
                  )
                ? 0
                : totalQuantitySold.strips /
                  totalTransactions.totalTransactionCampaignsStrip;

            const hcpsResult = await HCP.getTotalTransacting(filters);
            const totalTransactingHCPs = hcpsResult.find(hcp => hcp.type === 'total');

            dispatch(displayProductSummary({
                totalQuantitySold,
                totalTransactions,
                averageQuantity
            }));

            dispatch(displayHCPSummary({ totalTransactingHCPs }));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getBrandMix = () => {
    return async (dispatch, getState) => {
        const trigger = 'brandMix';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const { data: brandMix } = await Product.getBrandMix(filters);
            dispatch(displayBrandMix(brandMix));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }

        dispatch(apiRequestEnd(trigger));
    };
};

export const getMonthlyTransactions = () => {
    return async (dispatch, getState) => {
        const trigger = 'monthlyQuantitySold';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const monthlyTransactions = await Product.getMonthlyTransactions(filters);
            dispatch(displayMonthlyTransactions(monthlyTransactions));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }
        
        dispatch(apiRequestEnd(trigger));
    };
};

export const getTransactionFeed = ({ limit }) => {
    return async (dispatch, getState) => {
        const trigger = 'transactionFeed';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const { data: transactionFeed } = await Product.getTransactionFeed(filters, limit);
            dispatch(displayTransactionFeed(transactionFeed));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }
        
        dispatch(apiRequestEnd(trigger));
    };
};

export const getRegistrationTransactionReport = skip => {
    return async (dispatch, getState) => {
        const trigger = 'registrationTransactionReport';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const { data } = await Product.getRegistrationTransactionReport(filters, skip);
            dispatch(displayRegistrationTransactionReport(data));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }
        
        dispatch(apiRequestEnd(trigger));
    };
};

export const getRegistrationTransactionSummary = () => {
    return async (dispatch, getState) => {
        const trigger = 'registrationTransactionSummary';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const { data } = await Product.getRegistrationTransactionSummary(filters);
            dispatch(displayRegistrationTransactionSummary(data));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }
        
        dispatch(apiRequestEnd(trigger));
    };
};

export const getRetailerRebateAndBoxes = () => {
    return async (dispatch, getState) => {
        const trigger = 'retailerRebateAndBoxes';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const { data } = await Product.getRebate(filters);
            const box = await Product.getBoxes(filters);
            const finalData = {
                rebate: data,
                box: box.data
            }
            dispatch(displayRetailerRebateAndBoxes(finalData));
        }
        catch (e) {
            dispatch(displayProductError({ 
                status: e.status, 
                message: e.message
            }));
            dispatch(apiRequestEnd(trigger));
        }
        
        dispatch(apiRequestEnd(trigger));
    };
};


