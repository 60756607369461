// Middlewares
import { applyMiddleware, createStore, combineReducers } from 'redux';
import { composeWithDevTools  } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';

// Reducers
import apiResponseReducer from '../reducers/api-response';
import filterReducer from '../reducers/filter';
import sessionReducer from '../reducers/session';
import uiReducer from '../reducers/ui';
import userReducer from '../reducers/user';
import productReducer from '../reducers/product';
import patientReducer from '../reducers/patient';
import hcpReducer from '../reducers/hcp';
import reportReducer from '../reducers/report';

// Action Types
import { LOGOUT_SUCCESS } from '../constants/action-types';


const appReducer = combineReducers({
    apiResponse: apiResponseReducer,
    filter: filterReducer,
    session: sessionReducer,
    ui: uiReducer,
    user: userReducer,
    product: productReducer,
    patient: patientReducer,
    hcp: hcpReducer,
    report: reportReducer,
});

// This is used to reset the reducer to it's initial state upon logging out.
const rootReducer = (state, action) => {
    if (action.type === LOGOUT_SUCCESS)
        state = undefined;

    return appReducer(state, action);
};

export default () => {
    const composeEnhancers = composeWithDevTools({});

    const store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
}