import {
    DISPLAY_HCP_PAGE_ERROR,
    GET_HCP_SUMMARY,
    GET_PHARMACY_SUMMARY,
    GET_PHYSICIAN_SUMMARY,
    GET_PHARMACY_ACCOUNT_LEADERBOARD,
    GET_PHYSICIAN_ACCOUNT_LEADERBOARD,
    GET_PHARMACY_ORGANIZATON_LEADERBOARD,
    GET_PHYSICIAN_ORGANIZATON_LEADERBOARD,
    GET_PHARMACY_ORGANIZATION_SUMMARY,
    GET_PHYSICIAN_ORGANIZATION_SUMMARY,
    GET_PHARMACY_ACCOUNT_SUMMARY,
    GET_PHYSICIAN_ACCOUNT_SUMMARY,
    GET_ORGANIZATION_MONTHLY_TRANSACTIONS,
    GET_ACCOUNT_MONTHLY_TRANSACTIONS,
    GET_BRAND_TIMEGRAPH
} from '../../constants/action-types';

export const displayHCPSummary = data => ({
    type: GET_HCP_SUMMARY,
    payload: data
});

export const displayPharmacySummary = data => ({
    type: GET_PHARMACY_SUMMARY,
    payload: data
});

export const displayPhysicianSummary = data => ({
    type: GET_PHYSICIAN_SUMMARY,
    payload: data
});

export const displayHCPerror = ({ status, message }) => ({
    type: DISPLAY_HCP_PAGE_ERROR,
    payload: { status, message }
});

export const displayAccountLeaderboard = ({ data, userType }) => ({
    type: userType === 'pharmacist' 
        ? GET_PHARMACY_ACCOUNT_LEADERBOARD 
        : GET_PHYSICIAN_ACCOUNT_LEADERBOARD,
    payload: data
});

export const displayOrganizationLeaderboard = ({ data, userType }) => ({
    type: userType === 'pharmacist' 
        ? GET_PHARMACY_ORGANIZATON_LEADERBOARD 
        : GET_PHYSICIAN_ORGANIZATON_LEADERBOARD,
    payload: data
});

export const displayAccountSummary = ({ data, userType }) => ({
    type: userType === 'pharmacist' 
        ? GET_PHARMACY_ACCOUNT_SUMMARY 
        : GET_PHYSICIAN_ACCOUNT_SUMMARY,
    payload: data
});

export const displayOrganizationSummary = ({ data, userType }) => ({
    type: userType === 'pharmacist' 
        ? GET_PHARMACY_ORGANIZATION_SUMMARY 
        : GET_PHYSICIAN_ORGANIZATION_SUMMARY,
    payload: data
});

export const displayMonthlyOrganizationTransactions = data => ({
    type: GET_ORGANIZATION_MONTHLY_TRANSACTIONS,
    payload: data
});

export const displayMonthlyAccountTransactions = data => ({
    type: GET_ACCOUNT_MONTHLY_TRANSACTIONS,
    payload: data
});

export const displayBrandTimegraph = data => ({
    type: GET_BRAND_TIMEGRAPH,
    payload: data
});