import axios from 'axios';
import Warp from 'warp-sdk-js';

// Set defaults
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

const route = 'filters';
const GET = 'get';

const Request = async ({method, url, params}) => {
    return await axios({
        method,
        url,
        params,
        headers: {
            'x-warp-session-token': Warp.User.getSessionToken()
        }
    });
}

export default class Filter {
    static getBrands = async () => {
        const url = `/${route}/get-brands`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2 // TODO - Change this to retrieve from the redux store.
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getProducts = async brandIds => {
        const url = `/${route}/get-products`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    brand_ids: JSON.stringify(brandIds)
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getAccounts = async () => {
        const url = `/${route}/get-accounts`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getOrganizations = async () => {
        const url = `/${route}/get-organizations`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getZuelligAccountName = async () => {
        const url = `/${route}/get-zuellig-account-name`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getZuelligAccountGroup = async () => {
        const url = `/${route}/get-zuellig-group-name`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }

    static getSalesExecutive = async () => {
        const url = `/${route}/get-sales-executive-name`;

        try {
            const request = await Request({
                method: GET,
                url,
                params: {
                    promotion_id: 2
                }
            });

            return request.data;
        }
        catch (e) {
            throw new Error(e.message);
        }
    }
}

