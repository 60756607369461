import Request from './request';

const route = 'reports';

export default class Report {
    static downloadRebateSummaryReport = async (filters, params) => {
        try {
            const filename = `Pfizer_Malaysia-Patient_Rebate_Summary_Report-${filters.dateFrom}_to_${filters.dateTo}.xlsx`;
            return await Request.download(`/${route}/rebate-summary-report`, filters, params, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getPatientComplianceReport = async (filters, params) => {
        try {
            return await Request.get(`/${route}/patient-compliance-report`, {
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo
            }, params);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static getRefillPatientOutletTransactionReport = async (filters, params) => {
        try {
            return await Request.get(`/${route}/refill-patient-outlet-transaction-report`, {
                dateFrom: filters.dateFrom,
                dateTo: filters.dateTo
            }, params);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadRegistrationAndTransactionReport = async (filters, params) => {
        try {
            return await Request.get(`/${route}/patient-registration-and-transaction-report`, filters, params);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadPatientPageReport = async (params, data) => {
        try {
            const filename = `PatientsPageReport.xlsx`;

            return await Request.post(`/${route}/patient-page-report`, params, data, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }

    static downloadProductPageReport = async (params, data) => {
        try {
            const filename = `ProductsPageReport.xlsx`;

            return await Request.post(`/${route}/product-page-report`, params, data, filename);
        }
        catch(e) {
            throw new Error(e.message);
        }
    }
}