import {
    DISPLAY_REPORT_PAGE_ERROR,
    GET_PATIENT_COMPLIANCE,
    GET_REFILL_PATIENT_OUTLET
} from '../constants/action-types';

const defaultState = {
    patientComplianceReport: [],
    refillPatientOutletReport: [],
    error: {
        status: null,
        message: null
    }
};

const reportReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case DISPLAY_REPORT_PAGE_ERROR: 
            return {
                ...state,
                error: payload
            };
        case GET_PATIENT_COMPLIANCE:
            return {
                ...state,
                patientComplianceReport: payload
            };
        case GET_REFILL_PATIENT_OUTLET:
            return {
                ...state,
                refillPatientOutletReport: payload
            };
        default:
            return state;
    }
}

export default reportReducer;