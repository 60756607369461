import React, { createRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';

// Components
import { SummaryCard, DataCard } from '../ui/Card';
import AggregatedValue from '../ui/AggregatedValue';
import { Grid, TableRow, TableCell, Button } from '@material-ui/core';
import { PieChart, LineGraph, MultiLineGraph } from '../ui/Chart';
import Table from '../ui/Table';

// Utility
import {
  downloadChartImage,
  downloadFile,
  getChartImage,
} from '../../utils/download';

// Actions
import {
  getProductSummary,
  getBrandMix,
  getMonthlyTransactions,
  getTransactionFeed,
  getRetailerRebateAndBoxes,
} from '../../actions/product';

import { downloadProductPageReport } from '../../actions/report';

// Icons
import { SaveAltTwoTone as SaveAlt } from '@material-ui/icons';

//validator
import { checkRangeDateDownloadExport } from '../../utils/date-validator';

import Dialog from '../../components/ui/Dialog';

//throws an error (super expression must either be null or a function) if import.
const Excel = require('exceljs');

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.brandMixChart = createRef(null);
    this.timeGraphChart = createRef(null);
    this.retailerPerformanceChart = createRef(null);
  }

  state = {
    productDialog: false
  }

  interval = null;
  componentDidMount() {
    this.getProductSummary();
    this.getBrandMix();
    this.getMonthlyTransactions();
    this.getTransactionFeed();
    this.getRebateAndBoxes();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  closeModal = () => {
    this.setState({ 
      productDialog: false, 
    });
  }

  downloadBrandMixImage = async () => {
    downloadChartImage(this.brandMixChart.current.chart, 'Brand-Mix');
  };

  downloadTimeGraphImage = () => {
    downloadChartImage(this.timeGraphChart.current.chart, 'Timegraph');
  };

  downloadRetailerPerformanceImage = () => {
    downloadChartImage(
      this.retailerPerformanceChart.current.chart,
      'Retailer-Performance'
    );
  };

  downloadProductSummaryReport = async () => {
    const alignmentRight = { horizontal: 'right' },
      alignmentLeft = { horizontal: 'left' };
    const { product, filter, hcp } = this.props;
    const {
      totalQuantitySold,
      totalTransactionBatches,
      totalTransactionCampaigns,
      brandMix,
      monthlyTransactions,
      retailerRebate,
    } = product;

    const { dateFrom, dateTo, selectedAccounts, selectedBrandIds } = filter;

    const isRangeDateValid = checkRangeDateDownloadExport({
      start_date: dateFrom,
      end_date: dateTo,
    });

    if (!isRangeDateValid) {
      this.setState({
        productDialog: true,
      });
      return;
    }

    const workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet('Product Summary', {
      views: [{ showGridLines: false }],
    });
    sheet.pageSetup.margins = {
      left: 0.7,
      right: 0.7,
      top: 0.75,
      bottom: 0.75,
      header: 0.3,
      footer: 0.3,
    };

    sheet.columns = [
      { alignment: alignmentLeft },
      { width: 30, alignment: alignmentLeft },
      { width: 25, alignment: alignmentRight },
    ];
    sheet.addRow([]);

    sheet.addRow(['', 'Filters Applied']);

    if (selectedAccounts && selectedAccounts.length === 0) {
      sheet
        .addRow(['', 'Account/Outlet', '-'])
        .getCell(3).alignment = alignmentRight;
    } else {
      selectedAccounts.forEach((el, index) => {
        if (index === 0)
          sheet
            .addRow(['', 'Account/Outlet', el])
            .getCell(3).alignment = alignmentRight;
        else sheet.addRow(['', '', el]).getCell(3).alignment = alignmentRight;
      });
    }

    sheet
      .addRow(['', 'Start Date', dateFrom])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow(['', 'End Date', dateTo])
      .getCell(3).alignment = alignmentRight;

    if (selectedBrandIds && selectedBrandIds.length === 0) {
      sheet.addRow(['', 'Brand', '-']).getCell(3).alignment = alignmentRight;
    } else {
      selectedBrandIds.forEach((el, index) => {
        if (index === 0)
          sheet.addRow(['', 'Brand', el]).getCell(3).alignment = alignmentRight;
        else sheet.addRow(['', '', el]).getCell(3).alignment = alignmentRight;
      });
    }

    sheet.addRow([]);
    sheet.addRow([]);

    sheet
      .addRow([
        '',
        'Total Boxes Sold',
        totalQuantitySold ? totalQuantitySold.box : '-',
      ])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow([
        '',
        'Total Strips Sold',
        totalQuantitySold ? totalQuantitySold.strips : '-',
      ])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow(['', 'Total Transaction Batches', totalTransactionBatches])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow(['', 'Total Transaction Campaigns', totalTransactionCampaigns])
      .getCell(3).alignment = alignmentRight;

    sheet
      .addRow(['', 'Total Transacting HCPs', hcp.totalTransactingHCPs])
      .getCell(3).alignment = alignmentRight;
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Brand Mix']);

    sheet.addRow(['', 'Brands', 'Boxes Sold']);
    brandMix.data.forEach(el => {
      let row = sheet.addRow(['', el.id, el.y]);
      row.alignment = alignmentRight;
    });
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Timegraph']);

    sheet.addRow(['', 'Month', 'Boxes Sold']);
    monthlyTransactions.data.forEach(el => {
      let row = sheet.addRow(['', el.name, el.y]);
      row.alignment = alignmentRight;
    });
    sheet.addRow([]);
    sheet.addRow([]);

    sheet.addRow(['', 'Retailer Performance']);

    sheet.addRow(['', 'Month', 'Total Boxes', 'Rebate']);
    retailerRebate.box.forEach((el, index) => {
      let row = sheet.addRow([
        '',
        el.month,
        el.boxes,
        retailerRebate.rebate[index].rebate,
      ]);
      row.alignment = alignmentRight;
    });

    const buf = await workbook.xlsx.writeBuffer();
    const url = window.URL.createObjectURL(new Blob([buf]));

    downloadFile(url, 'Product Summary.xlsx');
    window.URL.revokeObjectURL(url);
  };

  downloadProductPageReport = async () => {
    const { dispatch } = this.props;

    const {
      totalQuantitySold,
      totalTransactionBatches,
      totalTransactionCampaigns,
      brandMix,
      monthlyTransactions,
      retailerRebate,
    } = this.props.product;

    const { totalTransactingHCPs } = this.props.hcp;

    const {
      dateFrom,
      dateTo,
      selectedAccounts,
      selectedBrandIds,
    } = this.props.filter;

    const isRangeDateValid = checkRangeDateDownloadExport({
      start_date: dateFrom,
      end_date: dateTo,
    });

    if (!isRangeDateValid) {
      this.setState({
        productDialog: true,
      });
      return;
    }

    const brandMixBase64 = await getChartImage(
      this.brandMixChart.current.chart
    );
    const timeGraphBase64 = await getChartImage(
      this.timeGraphChart.current.chart
    );
    const retailerPerformanceBase64 = await getChartImage(
      this.retailerPerformanceChart.current.chart
    );

    dispatch(
      downloadProductPageReport({
        totalQuantitySold,
        totalTransactionBatches,
        totalTransactionCampaigns,
        brandMix,
        monthlyTransactions,
        retailerRebate,
        totalTransactingHCPs,
        dateFrom,
        dateTo,
        selectedAccounts,
        selectedBrandIds,
        brandMixBase64,
        timeGraphBase64,
        retailerPerformanceBase64,
      })
    );
  };

  getProductSummary = () => {
    const { dispatch } = this.props;
    dispatch(getProductSummary());
  };

  getBrandMix = () => {
    const { dispatch } = this.props;
    dispatch(getBrandMix());
  };

  getMonthlyTransactions = () => {
    const { dispatch } = this.props;
    dispatch(getMonthlyTransactions());
  };

  getTransactionFeed = () => {
    const { dispatch } = this.props;
    const limit = 1000;
    const loadTime = 5; // in minutes

    dispatch(getTransactionFeed({ limit }));

    // Fetch values again after 5 minutes (depending on loadTime)
    this.interval = setInterval(() => {
      dispatch(getTransactionFeed({ limit }));
    }, loadTime * 60000);
  };

  getRebateAndBoxes = () => {
    const { dispatch } = this.props;
    dispatch(getRetailerRebateAndBoxes());
  };

  getTableColumns = () => [
    'Id',
    'Date',
    'Organization',
    'Sold By',
    'Product',
    'Qty (in Boxes)',
  ];

  render() {
    const { product, ui, hcp } = this.props;
    const {
      totalQuantitySold,
      totalTransactionBatches,
      totalTransactionCampaigns,
      averageQuantity,
      brandMix,
      monthlyTransactions,
      transactionFeed,
      retailerRebate,
    } = product;

    const { productDialog } = this.state;

    const totalTransactingHCPs = hcp.totalTransactingHCPs;

    return (
      <div>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          spacing={16}
        >
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-end"
              spacing={10}
              style={{ marginBottom: 10 }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={this.downloadProductPageReport}
              >
                <SaveAlt fontSize="small" style={{ marginRight: 6 }} /> Export
                All To Excel
              </Button>
            </Grid>

            <SummaryCard
              title="Product Summary"
              subtitle="General information"
              actionButton={{
                text: 'Export Excel',
                iconComponent: (
                  <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                ),
                action: this.downloadProductSummaryReport,
              }}
            >
              <AggregatedValue
                md={6}
                value={totalQuantitySold && totalQuantitySold.box}
                valueInBoxes={true}
                description="Total Boxes Sold"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={averageQuantity.box}
                valueInBoxes={true}
                description="Average Quantity (in boxes)"
                tooltip="Average quantity sold per transaction"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={totalQuantitySold && totalQuantitySold.strips}
                valueInBoxes={true}
                description="Total Strips Sold"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={averageQuantity.strip}
                valueInBoxes={true}
                description="Average Quantity (in strips)"
                tooltip="Average quantity sold per transaction"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={totalTransactionBatches}
                description="Total Transaction Batches"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={totalTransactionCampaigns}
                description="Total Transaction Campaigns"
                isLoading={ui.productSummary.isLoading}
              />

              <AggregatedValue
                md={6}
                value={totalTransactingHCPs}
                description="Total Transacting HCPs"
                tooltip="Transacting Health Care Professionals, both Pharmacies and Physicians"
                isLoading={ui.productSummary.isLoading}
              />
            </SummaryCard>
          </Grid>

          <Grid item xs={12}>
            <DataCard
              title="Brand Mix"
              subtitle="Product Segmentation, based on Quantity Sold (in boxes)"
              actionButton={{
                text: 'Export Image',
                iconComponent: (
                  <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                ),
                action: this.downloadBrandMixImage,
              }}
              isLoading={ui.brandMix.isLoading}
            >
              <PieChart
                filename="Brand Mix"
                ref={this.brandMixChart}
                series={brandMix}
                height={250}
              />
            </DataCard>
          </Grid>

          <Grid item xs={12}>
            <DataCard
              title="Timegraph"
              subtitle="Total Quantity Sold (in boxes) monthly"
              actionButton={{
                text: 'Export Image',
                iconComponent: (
                  <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                ),
                action: this.downloadTimeGraphImage,
              }}
              isLoading={ui.monthlyQuantitySold.isLoading}
            >
              <LineGraph
                filename="Timegraph"
                ref={this.timeGraphChart}
                series={monthlyTransactions}
                yAxis="Total Boxes"
                xAxis="Period"
              />
            </DataCard>
          </Grid>

          <Grid item xs={12}>
            <DataCard
              title="Retailer Performance"
              subtitle=""
              actionButton={{
                text: 'Export Image',
                iconComponent: (
                  <SaveAlt fontSize="small" style={{ marginRight: 6 }} />
                ),
                action: this.downloadRetailerPerformanceImage,
              }}
              isLoading={ui.retailerRebateAndBoxes.isLoading}
            >
              <MultiLineGraph
                filename="Retailer Performance"
                ref={this.retailerPerformanceChart}
                series={retailerRebate}
                yAxis="Box/Rebate"
                xAxis="Month"
              />
            </DataCard>
          </Grid>

          <Grid item xs={12}>
            <DataCard
              title="Transactions View"
              subtitle="Product transaction feed (updated every 5 minutes)"
              isLoading={ui.transactionFeed.isLoading}
            >
              <Table
                columns={this.getTableColumns()}
                totalCount={transactionFeed.length}
                rows={transactionFeed.map((row, index) => (
                  <TableRow key={index} hover>
                    <TableCell>{row.transaction_id}</TableCell>
                    <TableCell>
                      {moment(row.transaction_date).format('YYYY-MM-DD, h:mma')}
                    </TableCell>
                    <TableCell>{row.organization_branch}</TableCell>
                    <TableCell>{row.healthcare_professional}</TableCell>
                    <TableCell>{row.product}</TableCell>
                    <TableCell numeric>{row.quantity}</TableCell>
                  </TableRow>
                ))}
              />
            </DataCard>
          </Grid>

          <Dialog
                open={productDialog}
                content={"Please set your range date up to 1 year only when exporting"}>
                <Button 
                    onClick={() => this.closeModal()} 
                    color='primary' 
                    variant='contained'>
                    Close
                </Button>
            </Dialog>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  product: state.product,
  ui: state.ui,
  hcp: state.hcp,
  filter: state.filter,
});

export default connect(mapStateToProps)(ProductPage);
