import React from 'react';
import { connect } from 'react-redux';

// Components
import { Grid } from '@material-ui/core';
import { DataCard } from '../ui/Card';
import { Typography } from '@material-ui/core';

// Icons
import {
  SaveAltTwoTone as SaveAlt,
} from '@material-ui/icons';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Actions
import { getRefillPatientOutletTransactionReport } from '../../actions/report';

class RefillPatientOutletTransactionPage extends React.Component {
  componentDidMount() {
    this.getRefillPatientOutletTransaction();
  }

  getRefillPatientOutletTransaction = () => {
    const { dispatch } = this.props;

    dispatch(getRefillPatientOutletTransactionReport());
  }

  displayRefillPatientOutletTransaction = (refillPatientOutletReport) => {
    let brandNames = [],
      oneOutlet = [],
      moreThanOneOutlet = [];

      refillPatientOutletReport.forEach(product => {

        let checkBrandNameExist = (brandNames.indexOf(product.brand) > -1);

        if(!checkBrandNameExist){
          brandNames.push(`${product.brand}`);
        }

        product.outlets_transacted === '1 outlet' ? oneOutlet.push(parseInt(product.refill_patients)) :
        moreThanOneOutlet.push(parseInt(product.refill_patients));
        
      });

    const chartOptions = {
      chart: {
        type: 'column'
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: brandNames
      },
      yAxis: {
        min: 0,
        tickInterval: 10,
        title: {
          text: 'Refill Patient Outlet'
        },
        labels: {
          formatter: function () {
            return this.value + '%';
          }
        }
      },
      legend: {
        align: 'left',
        x: 30,
        verticalAlign: 'top',
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || 'white',
        borderColor: '#CCC',
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'percent'
        }
      },
      series: [{
        name: '1 Outlet',
        data: oneOutlet
      }, {
        name: 'More Than One Outlet',
        data: moreThanOneOutlet
      }]
    };

    return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
  }

  render() {
    const { ui, report: { refillPatientOutletReport } } = this.props;

    return (
      <Grid spacing={16} container>
        <Grid item xs={12}>
          <DataCard
            title="Refill Patient Outlet-Transactions"
            subtitle="Data report for refill patients moving across same/different outlets"
            isLoading={ui.refillPatientOutletTransaction.isLoading}
          >
            {refillPatientOutletReport.length > 0 ? (
              <>
                {this.displayRefillPatientOutletTransaction(refillPatientOutletReport)}
              </>
            ) : (
              <Typography>No data found</Typography>
            )}
          </DataCard>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  report: state.report,
  ui: state.ui,
  filter: state.filter,
});

export default connect(mapStateToProps)(RefillPatientOutletTransactionPage);
