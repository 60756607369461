import {
    GET_PATIENT_SUMMARY,
    GET_MONTHLY_ENROLLED_PATIENTS,
    GET_MONTHLY_TRANSACTING_PATIENTS,
    GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS,
    DISPLAY_PATIENT_PAGE_ERROR
} from '../constants/action-types';

const defaultState = {
    totalEnrolledPatients: 0,
    totalTransactingPatients: 0,
    totalVisitingPatients: 0,
    averagePatientVisit: 0,
    averageQuantityPerTransaction: 0,
    monthlyEnrolled: {},
    monthlyTransacting: {},
    monthlyTransactionRefillerAndPatient: [],
    error: {
        status: null,
        message: null
    }
};

const patientReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PATIENT_SUMMARY:
            return {
                ...state,
                totalEnrolledPatients: payload.totalEnrolledPatients,
                totalTransactingPatients: payload.totalTransactingPatients,
                totalVisitingPatients: payload.totalVisitingPatients,
                averagePatientVisit: payload.averagePatientVisit,
                averageQuantityPerTransaction: payload.averageQuantityPerTransaction,
            };
        case GET_MONTHLY_ENROLLED_PATIENTS:
            return {
                ...state,
                monthlyEnrolled: {
                    name: 'Total Patients',
                    data: payload.map(monthItem => ({
                        name: monthItem.month,
                        y: parseInt(monthItem.total_patients, 10)
                    }))
                }
            };
        case GET_MONTHLY_TRANSACTING_PATIENTS:
            return {
                ...state,
                monthlyTransacting: {
                    name: 'Total Patients',
                    data: payload.map(monthItem => ({
                        name: monthItem.month,
                        y: parseInt(monthItem.total_patients, 10)
                    }))
                }
            };
        case GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS:{
            return {
                ...state,
                monthlyTransactionRefillerAndPatient: payload
            }
        }
        case DISPLAY_PATIENT_PAGE_ERROR: 
            return {
                ...state,
                error: payload
            };
        default:
            return state;
    }
}

export default patientReducer;