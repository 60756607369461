// Classes
import Report from '../../classes/report';

// Action Creators
import { 
    displayReportError, 
    displayPatientComplianceReport,
    displayRefillPatientOutletReport
 } from './action-creators';
import { apiRequestStart, apiRequestEnd } from '../ui';

export const downloadRebateSummaryReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'rebateSummary';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;
        const user = getState().user;

        try {
            const response = await Report.downloadRebateSummaryReport(filters, { email: user.email });
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};

export const getPatientComplianceReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'patientCompliance';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.getPatientComplianceReport(filters);

            dispatch(displayPatientComplianceReport(response.data));
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }        
    };
};

export const getRefillPatientOutletTransactionReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'refillPatientOutletTransaction';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;

        try {
            const response = await Report.getRefillPatientOutletTransactionReport(filters);
            dispatch(displayRefillPatientOutletReport(response.data));

            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }        
    };
};

export const downloadRegistrationAndTransactionReport = () => {
    return async (dispatch, getState) => {
        const trigger = 'transactionAndRegistration';
        dispatch(apiRequestStart(trigger));
        const filters = getState().filter;
        const user = getState().user;
        try {
            const response = await Report.downloadRegistrationAndTransactionReport(filters, { email: user.email });
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};

export const downloadPatientPageReport = data => {
    return async (dispatch, getState) => {
        const trigger = 'patientPageReport';
        dispatch(apiRequestStart(trigger));
        const user = getState().user;
        try {
            const response = await Report.downloadPatientPageReport({ email: user.email}, data );
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};

export const downloadProductPageReport = data => {
    return async (dispatch, getState) => {
        const trigger = 'productPageReport';
        dispatch(apiRequestStart(trigger));
        const user = getState().user;
        try {
            const response = await Report.downloadProductPageReport({ email: user.email}, data );
            dispatch(apiRequestEnd(trigger));
            return response;
        }
        catch (e) {
            dispatch(apiRequestEnd(trigger));
            dispatch(displayReportError({ 
                status: e.status,
                message: e.message
            }));
        }
    };
};