import axios from 'axios';
import moment from 'moment-timezone';
import Warp from 'warp-sdk-js';

// Set defaults
axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

/**
 * @author Snaefel Javier
 * @desc Request functions for REST API (get/post)
 * Uses the library Axios
 */

export default class Request {
    /**
     * @function get
     * @description get request
     * 
     * @param brands {array}
     * @param products {array}
     * @param from {datetime}
     * @param to {datetime}
     * @param organizations {array}
     * @param accounts {array}
     */
    static get = async (url, filters, params) => {
        if (!url)
            throw new Error('Url is not defined');
        if (!filters)
            throw new Error('Filters is not defined');
        if (filters && Object.keys(filters).length === 0)
            throw new Error('Filters object does not contain any keys');

        const {
          selectedBrandIds = [],
          selectedProductIds = [],
          dateFrom,
          dateTo,
          selectedOrganizations = [],
          selectedAccounts = [],
          selectedZuelligAccountName = [],
          selectedZuelligGroupName = [],
          selectedSalesExecutive = [],
          selectedAccountType = '',
          userType = [],
        } = filters;

        return await axios({
            method: 'get',
            url: url,
            params: {
                brand_ids: JSON.stringify(selectedBrandIds),
                product_ids: JSON.stringify(selectedProductIds),
                start_date: moment(dateFrom).startOf('day').toISOString(),
                end_date: moment(dateTo).endOf('day').toISOString(),
                branches: JSON.stringify(selectedOrganizations),
                account_numbers: JSON.stringify(selectedAccounts),
                user_type: JSON.stringify(userType),
                zuellig_account_name: JSON.stringify(selectedZuelligAccountName),
                zuellig_account_type: JSON.stringify(selectedAccountType),
                zuellig_group_name: JSON.stringify(selectedZuelligGroupName),
                zuellig_sales_executive: JSON.stringify(selectedSalesExecutive),
                ...params
            },
            headers: {
                'x-warp-session-token': Warp.User.getSessionToken()
            }
        });
    }

    /**
     * @function download
     * @description download file
     * 
     * @param from {datetime}
     * @param to {datetime}
     * @param brands {array}
     * @param products {array}
     * @param organizations {array}
     * @param accounts {array}
     */
    static download = async (url, filters, params, filename) => {
        if (!url)
            throw new Error('Url is not defined');
        if (!filters)
            throw new Error('Filters is not defined');
        if (filters && Object.keys(filters).length === 0)
            throw new Error('Filters object does not contain any keys');
        
        const {
            dateFrom,
            dateTo
        } = filters;

        const response = await axios({
            method: 'get',
            url: url,
            params: {
                start_date: moment(dateFrom).startOf('day').toISOString(),
                end_date: moment(dateTo).endOf('day').toISOString(),
                ...(filters.selectedBrandIds ? { brand_ids: JSON.stringify(filters.selectedBrandIds) } : {}),
                ...(filters.selectedProductIds ? { product_ids: JSON.stringify(filters.selectedProductIds) } : {}),
                ...(filters.selectedAccounts ? { account_numbers: JSON.stringify(filters.selectedAccounts) } : {}),
                ...params
            },
            headers: {
                'x-warp-session-token': Warp.User.getSessionToken()
            },
            responseType: 'blob',
        });

        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename ? filename : 'file.txt');
        document.body.appendChild(link);
        return link.click();
    }

    static post = async (url, params, data, filename) => {
        if (!url)
            throw new Error('Url is not defined');

        const response = await axios({
            method: 'post',
            url: url,
            params,
            data,
            headers: {
                'x-warp-session-token': Warp.User.getSessionToken()
            },
            responseType: 'blob',
        });

        const fileUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', filename ? filename : 'file.txt');
        document.body.appendChild(link);
        return link.click();
    }
};