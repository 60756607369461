import { API_REQUEST } from '../constants/action-types';

const defaultState = {
    login: { isLoading: false },

    brands: { isLoading: false },
    products: { isLoading: false },
    accounts: { isLoading: false },
    organizations: { isLoading: false },
    zuelligAccount: { isLoading: false},
    zuelligAccountGroup : { isLoading: false},
    salesExective: {isLoading: false},

    programSummary: { isLoading: false },
    pharmacySummary: { isLoading: false },
    physicianSummary: { isLoading: false },

    accountLeaderboard: { isLoading: false },
    organizationLeaderboard: { isLoading: false },

    accountsView: { isLoading: false },
    organizationsView: { isLoading: false },

    monthlyTransactions: { isLoading: false },
    
    // Products
    productSummary: { isLoading: false },
    brandMix: { isLoading: false },
    monthlyQuantitySold: { isLoading: false },
    transactionFeed: { isLoading: false },
    retailerRebateAndBoxes: { isLoading: false},
    brandTimegraph: { isLoading: false },


    // Patients
    patientSummary: { isLoading: false },
    monthlyEnrolledPatients: { isLoading: false },
    monthlyTransactingPatients: { isLoading: false },
    monthlyTransactionRefillersAndNewPatients: { isLoading: false},

    // Reports
    rebateSummary: { isLoading: false },
    patientCompliance: { isLoading: false },
    patientReenrollment: { isLoading: false },
    transactionAndRegistration: { isLoading: false },
    refillPatientOutletTransaction: { isLoading: false },
};

const uiReducer = (state = defaultState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
export default uiReducer;
