export const API_REQUEST = 'API_REQUEST';

// Login
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_UNAUTHORIZED = 'LOGIN_UNAUTHORIZED';

// Logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// Retrieve User
export const RETRIEVE_USER_SUCCESS = 'RETRIEVE_USER_SUCCESS';
export const RETRIEVE_USER_FAILED = 'RETRIEVE_USER_FAILED';

// Filters
export const ADD_FILTERS = 'ADD_FILTERS';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export const GET_BRANDS = 'GET_BRANDS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'; 
export const GET_ZUELLIG_ACCOUNT_NAME = 'GET_ZUELLIG_ACCOUNT_NAME'
export const GET_FILTERS = 'GET_FILTERS';


// Products
export const GET_PRODUCT_SUMMARY = 'GET_PRODUCT_SUMMARY';
export const GET_BRAND_MIX = 'GET_BRAND_MIX';
export const GET_MONTHLY_QUANTITY_SOLD = 'GET_MONTHLY_QUANTITY_SOLD';
export const GET_PRODUCT_TRANSACTION_FEED = 'GET_PRODUCT_TRANSACTION_FEED';
export const GET_DISPLAY_RETAILER_REBATE_AND_BOXES = 'GET_DISPLAY_RETAILER_REBATE_AND_BOXES';
export const GET_DISPLAY_RETAILER_BOXES = 'GET_DISPLAY_RETAILER_BOXES';
export const DISPLAY_PRODUCT_PAGE_ERROR = 'DISPLAY_PRODUCT_PAGE_ERROR';

// Patients
export const GET_PATIENT_SUMMARY = 'GET_PATIENT_SUMMARY';
export const GET_MONTHLY_ENROLLED_PATIENTS = 'GET_MONTHLY_ENROLLED_PATIENTS';
export const GET_MONTHLY_TRANSACTING_PATIENTS = 'GET_MONTHLY_TRANSACTING_PATIENTS';
export const DISPLAY_PATIENT_PAGE_ERROR = 'DISPLAY_PATIENT_PAGE_ERROR';
export const GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS = 'GET_MONTHLY_TRANSACTING_REFILLERS_AND_NEW_PATIENTS';

// HCPs
export const GET_HCP_SUMMARY = 'GET_HCP_SUMMARY';
export const DISPLAY_HCP_PAGE_ERROR = 'DISPLAY_HCP_PAGE_ERROR';
export const GET_BRAND_TIMEGRAPH = 'GET_BRAND_TIMEGRAPH';

// Accounts
export const GET_ENROLLED_ACCOUNTS = 'GET_ENROLLED_ACCOUNTS';
export const GET_TRANSACTING_ACCOUNTS = 'GET_TRANSACTING_ACCOUNTS';
export const GET_PHARMACY_ACCOUNT_LEADERBOARD = 'GET_PHARMACY_ACCOUNT_LEADERBOARD';
export const GET_PHYSICIAN_ACCOUNT_LEADERBOARD = 'GET_PHYSICIAN_ACCOUNT_LEADERBOARD';

export const GET_PHARMACY_ACCOUNT_SUMMARY = 'GET_PHARMACY_ACCOUNT_SUMMARY';
export const GET_PHYSICIAN_ACCOUNT_SUMMARY = 'GET_PHYSICIAN_ACCOUNT_SUMMARY';

export const GET_ACCOUNT_MONTHLY_TRANSACTIONS = 'GET_ACCOUNT_MONTHLY_TRANSACTIONS';

// Organizations
export const GET_ENROLLED_ORGANIZATIONS = 'GET_ENROLLED_ORGANIZATIONS';
export const GET_TRANSACTING_ORGANIZATIONS = 'GET_TRANSACTING_ORGANIZATIONS';

export const GET_PHARMACY_ORGANIZATON_LEADERBOARD = 'GET_PHARMACY_ORGANIZATON_LEADERBOARD'; 
export const GET_PHYSICIAN_ORGANIZATON_LEADERBOARD = 'GET_PHYSICIAN_ORGANIZATON_LEADERBOARD'; 

export const GET_PHARMACY_ORGANIZATION_SUMMARY = 'GET_PHARMACY_ORGANIZATION_SUMMARY';
export const GET_PHYSICIAN_ORGANIZATION_SUMMARY = 'GET_PHYSICIAN_ORGANIZATION_SUMMARY';

export const GET_ORGANIZATION_MONTHLY_TRANSACTIONS = 'GET_ORGANIZATION_MONTHLY_TRANSACTIONS';

// Program
export const GET_PROGRAM_SUMMARY = 'GET_PROGRAM_SUMMARY';
export const GET_REGISTRATION_TRANSACTION_SUMMARY = 'GET_REGISTRATION_TRANSACTION_SUMMARY';
export const GET_REGISTRATION_TRANSACTION_REPORT = 'GET_REGISTRATION_TRANSACTION_REPORT';

// Pharmacies
export const GET_PHARMACY_SUMMARY = 'GET_PHARMACY_SUMMARY';

// Physicians
export const GET_PHYSICIAN_SUMMARY = 'GET_PHYSICIAN_SUMMARY';

// Reports
export const DISPLAY_REPORT_PAGE_ERROR = 'DISPLAY_REPORT_PAGE_ERROR';
export const GET_PATIENT_COMPLIANCE = 'GET_PATIENT_COMPLIANCE';
export const GET_REFILL_PATIENT_OUTLET = 'GET_REFILL_PATIENT_OUTLET';