import { 
    GET_HCP_SUMMARY, 
    GET_PHARMACY_SUMMARY, 
    GET_PHYSICIAN_SUMMARY, 
    GET_PROGRAM_SUMMARY,
    GET_PHARMACY_ACCOUNT_LEADERBOARD,
    GET_PHARMACY_ORGANIZATON_LEADERBOARD,
    GET_PHARMACY_ORGANIZATION_SUMMARY,
    GET_PHYSICIAN_ORGANIZATION_SUMMARY,
    GET_PHARMACY_ACCOUNT_SUMMARY,
    GET_PHYSICIAN_ACCOUNT_SUMMARY,
    GET_ACCOUNT_MONTHLY_TRANSACTIONS,
    GET_ORGANIZATION_MONTHLY_TRANSACTIONS,
    GET_PHYSICIAN_ACCOUNT_LEADERBOARD,
    GET_PHYSICIAN_ORGANIZATON_LEADERBOARD,
    GET_BRAND_TIMEGRAPH
} from '../constants/action-types';

const defaultState = {
    monthlyTransactions: {},

    brandTimegraph:[],
    
    totalEnrolledAccounts: 0,
    totalTransactingAccounts: 0,

    totalEnrolledOrganizations: 0,
    totalTransactingOrganizations: 0,

    totalEnrolledHCPs: 0,
    totalTransactingHCPs: 0,

    pharmacy: {
        totalEnrolledPharmacyAccounts: 0,
        totalTransactingPharmacyAccounts: 0,

        totalEnrolledPharmacyOrganizations: 0,
        totalTransactingPharmacyOrganizations: 0,

        totalEnrolledPharmacists: 0,
        totalTransactingPharmacists: 0,

        totalTransactionBatches: 0,
        totalTransactionCampaigns: 0,
        totalQuantitySold: 0,

        pharmacyAccountLeaderboards: [],
        pharmacyOrganizationLeaderboards: [],

        pharmacyAccounts: [],
        pharmacyOrganizations: []
    },
    physician: {
        totalEnrolledPhysicianAccounts: 0,
        totalTransactingPhysicianAccounts: 0,
        
        totalEnrolledPhysicianOrganizations: 0,
        totalTransactingPhysicianOrganizations: 0,

        totalEnrolledPhysicians: 0,
        totalTransactingPhysicians: 0,

        totalTransactionBatches: 0,
        totalTransactionCampaigns: 0,
        totalQuantitySold: 0,

        physicianAccountLeaderboards: [],
        physicianOrganizationLeaderboards: [],
        
        physicianAccounts: [],
        physicianOrganizations: []
    }
};

const hcpReducer = (state = defaultState, action) => {
    const { type, payload } = action;

    switch (type) {
        case GET_PROGRAM_SUMMARY: 
            return {
                ...state,
                totalEnrolledAccounts: 
                    payload.totalEnrolledAccounts 
                    ? payload.totalEnrolledAccounts.total_accounts
                    : 0, 
                
                totalTransactingAccounts: 
                    payload.totalTransactingAccounts 
                    ? payload.totalTransactingAccounts.total_accounts
                    : 0, 
                
                totalEnrolledOrganizations: 
                    payload.totalEnrolledOrganizations 
                    ? payload.totalEnrolledOrganizations.total_organizations
                    : 0, 
                
                totalTransactingOrganizations: 
                    payload.totalTransactingOrganizations ? 
                    payload.totalTransactingOrganizations.total_organizations
                    : 0, 
            };
        case GET_HCP_SUMMARY:
            return {
                ...state,
                totalEnrolledHCPs: 
                    payload.totalEnrolledHCPs 
                    ? payload.totalEnrolledHCPs.total_users 
                    : 0,

                totalTransactingHCPs: 
                    payload.totalTransactingHCPs 
                    ? payload.totalTransactingHCPs.total_users
                    : 0
            };
        case GET_PHARMACY_SUMMARY:
            return {
                ...state,
                pharmacy: {
                    ...state.pharmacy,
                    ...{
                        totalEnrolledPharmacyAccounts: 
                            payload.totalEnrolledPharmacyAccounts 
                            ? payload.totalEnrolledPharmacyAccounts.total_accounts 
                            : 0,
                        
                        totalEnrolledPharmacyOrganizations: 
                            payload.totalEnrolledPharmacyOrganizations 
                            ? payload.totalEnrolledPharmacyOrganizations.total_organizations 
                            : 0,
                
                        totalTransactingPharmacyAccounts: 
                            payload.totalTransactingPharmacyAccounts 
                            ? payload.totalTransactingPharmacyAccounts.total_accounts 
                            : 0,
                        
                        totalTransactingPharmacyOrganizations: 
                            payload.totalTransactingPharmacyOrganizations 
                            ? payload.totalTransactingPharmacyOrganizations.total_organizations 
                            : 0,
                
                        totalEnrolledPharmacists: 
                            payload.totalEnrolledPharmacists 
                            ? payload.totalEnrolledPharmacists.total_users 
                            : 0,
                        
                        totalTransactingPharmacists: 
                            payload.totalTransactingPharmacists 
                            ? payload.totalTransactingPharmacists.total_users 
                            : 0,
                
                        totalTransactionBatches: 
                            payload.totalTransactionBatches,
                        totalTransactionCampaigns: 
                            payload.totalTransactionCampaigns,
                        totalQuantitySold: 
                            payload.totalQuantitySold 
                            ? payload.totalQuantitySold 
                            : {}
                    }
                }
            };
        case GET_PHYSICIAN_SUMMARY:
            return {
                ...state,
                physician: {
                    ...state.physician,
                    ...{
                        totalEnrolledPhysicianAccounts: 
                            payload.totalEnrolledPhysicianAccounts 
                            ? payload.totalEnrolledPhysicianAccounts.total_accounts 
                            : 0,
                        
                        totalEnrolledPhysicianOrganizations: 
                            payload.totalEnrolledPhysicianOrganizations 
                            ? payload.totalEnrolledPhysicianOrganizations.total_organizations 
                            : 0,
                
                        totalTransactingPhysicianAccounts: 
                            payload.totalTransactingPhysicianAccounts 
                            ? payload.totalTransactingPhysicianAccounts.total_accounts 
                            : 0,
                        
                        totalTransactingPhysicianOrganizations: 
                            payload.totalTransactingPhysicianOrganizations 
                            ? payload.totalTransactingPhysicianOrganizations.total_organizations 
                            : 0,
                
                        totalEnrolledPhysicians: 
                            payload.totalEnrolledPhysicians 
                            ? payload.totalEnrolledPhysicians.total_users 
                            : 0,
                        
                        totalTransactingPhysicians: 
                            payload.totalTransactingPhysicians 
                            ? payload.totalTransactingPhysicians.total_users 
                            : 0,
                
                        totalTransactionBatches: 
                            payload.totalTransactionBatches,
                        totalTransactionCampaigns: 
                            payload.totalTransactionCampaigns,
                        totalQuantitySold: 
                            payload.totalQuantitySold 
                            ? payload.totalQuantitySold : {}
                    }
                }
            };
        case GET_PHARMACY_ACCOUNT_LEADERBOARD:
            state.pharmacy['pharmacyAccountLeaderboards'] = {
                name: 'Quantity Sold in Boxes',
                colorByPoint: true,
                data: payload ? payload.map(account => ({
                    name: account.account_name,
                    y: parseInt(account.total_quantity_sold, 10)
                })) : []
            };
            
            return { ...state };
        case GET_PHARMACY_ORGANIZATON_LEADERBOARD:
            state.pharmacy['pharmacyOrganizationLeaderboards'] = {
                name: 'Quantity Sold in Boxes',
                colorByPoint: true,
                data: payload ? payload.map(outlet => ({
                    name: outlet.organization_branch,
                    y: parseInt(outlet.total_quantity_sold, 10)
                })) : []
            };

            return { ...state };
        case GET_PHYSICIAN_ACCOUNT_LEADERBOARD:
            state.physician['physicianAccountLeaderboards'] = {
                name: 'Quantity Sold in Boxes',
                colorByPoint: true,
                data: payload ? payload.map(account => ({
                    name: account.account_name,
                    y: parseInt(account.total_quantity_sold, 10)
                })) : []
            };
            
            return { ...state };
        case GET_PHYSICIAN_ORGANIZATON_LEADERBOARD:
            state.physician['physicianOrganizationLeaderboards'] = {
                name: 'Quantity Sold in Boxes',
                colorByPoint: true,
                data: payload ? payload.map(outlet => ({
                    name: outlet.organization_branch,
                    y: parseInt(outlet.total_quantity_sold, 10)
                })) : []
            };

            return { ...state };
        case GET_PHARMACY_ACCOUNT_SUMMARY:
            state.pharmacy['pharmacyAccounts'] = payload;

            return { ...state };
        case GET_PHARMACY_ORGANIZATION_SUMMARY:
            state.pharmacy['pharmacyOrganizations'] = payload;

            return { ...state };
        case GET_PHYSICIAN_ACCOUNT_SUMMARY:
            state.physician['physicianAccounts'] = payload;

            return { ...state };
        case GET_PHYSICIAN_ORGANIZATION_SUMMARY:
            state.physician['physicianOrganizations'] = payload;

            return { ...state };
        case GET_ACCOUNT_MONTHLY_TRANSACTIONS:
        case GET_ORGANIZATION_MONTHLY_TRANSACTIONS:
            return {
                ...state,
                monthlyTransactions: {
                    name: 'Total Transactions',
                    data: payload.map(monthItem => ({
                        name: monthItem.month,
                        y: parseInt(monthItem.total_transactions, 10)
                    }))
                }
            };
        case GET_BRAND_TIMEGRAPH:
            return {
                ...state,
                brandTimegraph: payload
            };
        default:
            return state;
    }
};

export default hcpReducer;