import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Warp from 'warp-sdk-js';
import "babel-polyfill";

import MTHPAppRouter from './routers/AppRouter';
import StayonAppRouter from './connect/stayon/routers/AppRouter';
import MYCareAppRouter from './connect/mycare/routers/AppRouter';
import configureStore from './store/configure-store';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { stayon, mycare, mthp, Header } from './components/ui/Theme';
import './config.js';
// import registerServiceWorker from './registerServiceWorker'; // Research what does this function do.=

import rematchStores, {
  rematchContext,
} from './connect/common/models/index.model';

const apiKey = process.env.REACT_APP_WARP_API_KEY;
const baseURL = process.env.REACT_APP_WARP_API_URL;
const timeout = 60;

// Initialie Warp
Warp.initialize({ apiKey, baseURL, timeout });

// Initialize Redux Store
const thunkStores = configureStore();

const { DASHBOARD_TYPE } = window.config;

var dashboard_theme = null;

switch (DASHBOARD_TYPE) {
  case 'stayon':
    dashboard_theme = stayon;
    break;
  case 'mycare':
    dashboard_theme = mycare;
    break;
  case 'mthp':
    dashboard_theme = mthp;
    break;
  default:
    break;
}

const jsx = (
  <>
    <Header />
    <Provider 
      context={ DASHBOARD_TYPE === 'mthp' ? null : rematchContext } 
      store={ DASHBOARD_TYPE === 'mthp' ? thunkStores : rematchStores }
    >
        <MuiThemeProvider theme={dashboard_theme}>
          {DASHBOARD_TYPE === 'mthp' ? (
            <MTHPAppRouter />
          ) : DASHBOARD_TYPE === 'stayon' ? (
            <StayonAppRouter />
          ) : DASHBOARD_TYPE === 'mycare' ? (
            <MYCareAppRouter />
          ) : null}
        </MuiThemeProvider>
    </Provider>
  </>
);

ReactDOM.render(jsx, document.getElementById('app'));

// registerServiceWorker();
